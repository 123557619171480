import {
  ScwMatMaxLengthRule,
  ScwMatMaxValueRule,
  ScwMatMinValueRule,
  ScwMatRangeValueRule,
  ScwMatRegexRule,
  ScwMatSelectRule,
} from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { TranslateService } from '@ngx-translate/core';
import { TabRowInterface } from '../../component/side-config-bar/side-config-bar.model';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { EWidgetType, WidgetConfigurationInterface } from '../../component/page-configuration/page-configuration.model';
import { ComponentNamesForUserConfiguration } from '../../../store/user-configuration/user-configuration.model';
import { IAllowedDomain } from '../../../store/main/main.model';
import * as _ from 'lodash';
import { ScwMatFormComponent } from '../../component/scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { Injectable } from '@angular/core';
import { DataTableHelperService } from '../datatable/datatable.helper.service';
import { NgForm } from '@angular/forms';
import { OeeAppState } from '../../../store/oee.reducer';
import { TFormData } from '../../model/interface/scw-form.model';
import { HelperService } from '../helper.service';
import {
  EConfigurationTypes,
  IColumnSortOrderConfiguration,
  IColumnSortOrderItem,
  IDropdownOptions,
  IGeneralConfigurationColumnSortOrderSection,
  ITableHeaderGroupItem,
  IUserConfigurationFromGeneralConfigurationApply,
  IWidgetConfiguration,
  TGeneralConfigurationSection,
} from '../../component/general-configuration/general-configuration.model';
import { IGenericObject } from '../../model/interface/generic.model';
import { ScwMatSelectComponent } from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.component';
import { ScwMatInputComponent } from '../../component/scw-mat-ui/scw-mat-input/scw-mat-input.component';

@Injectable({ providedIn: 'root' })
export class FormHelperService {
  constructor(private readonly translate: TranslateService, private readonly dataTableHelper: DataTableHelperService) {}

  public static emailAllowedDomainMatch(input: string, allowedDomains: IAllowedDomain[]): boolean {
    const emailDomain = input?.split('@')?.[1];

    return emailDomain && allowedDomains.some((domain: IAllowedDomain) => domain.domain === emailDomain);
  }

  public static getScwMatFormValuesWithDataPropertyKey(form: ScwMatFormComponent): { [p: string]: any } {
    const data: { [key: string]: any } = {};

    (form?.getFormElements() ?? [])?.forEach((inputChunks) => {
      for (const inputComponent of inputChunks) {
        if (!('dataPropertyKey' in inputComponent && inputComponent.dataPropertyKey !== undefined)) {
          return;
        }

        if (inputComponent instanceof ScwMatInputComponent) {
          data[inputComponent.dataPropertyKey] =
            _.isEmpty(inputComponent.inputModel) && typeof inputComponent.inputModel !== 'number'
              ? null
              : inputComponent.type === 'number' && !_.isNaN(Number(inputComponent.inputModel))
              ? Number(inputComponent.inputModel)
              : inputComponent.inputModel;
          continue;
        }

        if (inputComponent instanceof ScwMatSelectComponent) {
          data[inputComponent.dataPropertyKey] = _.isEmpty(inputComponent.inputModel)
            ? null
            : inputComponent.singleSelection
            ? _.first(inputComponent.inputModel)[inputComponent.primaryKey]
            : inputComponent.inputModel?.map((item: any) => item[inputComponent.primaryKey]);
          continue;
        }

        data[inputComponent.dataPropertyKey] = _.isNil(inputComponent.inputModel) ? null : inputComponent.inputModel;
      }
    });

    return data;
  }

  public getRequiredFormRule(): ScwMatSelectRule {
    return {
      required: true,
      message: this.translate.instant('scwMatForm.validation.required'),
    };
  }

  public getMaxLengthFormRule(maxLength: number): ScwMatMaxLengthRule {
    return {
      maxLength,
      message: this.translate.instant('scwMatForm.validation.maxLength', { maxLength }),
    };
  }

  public getMinValueFormRule(minValue: number): ScwMatMinValueRule {
    return {
      minValue,
      message: this.translate.instant('scwMatForm.validation.minValue', { minValue }),
    };
  }

  public getMaxValueFormRule(maxValue: number): ScwMatMaxValueRule {
    return {
      maxValue,
      message: this.translate.instant('scwMatForm.validation.maxValue', { maxValue }),
    };
  }

  public getRangeValueFormRule(minValue: number, maxValue: number): ScwMatRangeValueRule {
    return {
      rangeValue: {
        min: minValue,
        max: maxValue,
      },
      message: this.translate.instant('scwMatForm.validation.rangedValueInBetween', { min: minValue, max: maxValue }),
    };
  }

  public getIsMacAddressFormRule(): ScwMatRegexRule {
    return {
      pattern: new RegExp('^[0-9A-Fa-f]{12}$'),
      message: this.translate.instant('scwMatForm.validation.incorrectMacAddress'),
    };
  }

  public onApplyClick(
    data: { payload: TabRowInterface[] | ITableHeader[]; setAsDefault: boolean },
    defaultHeaderArray: ITableHeader[],
    defaultWidgetArray: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[],
    insertToFirst: boolean,
    insertToLast: boolean,
    pageStore: keyof OeeAppState,
    componentName: ComponentNamesForUserConfiguration,
    columnKeyPropertyName: string = 'name',
    customSetAsDefault: boolean = false,
    defaultHeaderGroupArray?: ITableHeader[],
  ): {
    headers: ITableHeader[];
    widgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[];
    headerGroups: ITableHeader[];
  } {
    const rows = data.payload;
    let headers = [];
    const widgets = [];
    const headerGroups = [];

    if (insertToFirst) {
      headers = [defaultHeaderArray[0]];
    }

    rows.forEach((item) => {
      if (item && item.selected) {
        if (item.type === PageConfigurationTypes.TABLE) {
          const headerData = defaultHeaderArray.find(
            (value: ITableHeader) => value.value === item[columnKeyPropertyName],
          );

          if (headerData?.value !== null) {
            headers.push({ ...headerData, selected: true });
          }
        } else if (item.type === PageConfigurationTypes.WIDGET) {
          const headerData = defaultWidgetArray.find(
            (value: WidgetConfigurationInterface<EWidgetType.KPI_CARD>) => value.name === item.name,
          );

          widgets.push({ ...headerData, selected: true });
        } else if (item.type === PageConfigurationTypes.HEADER_GROUP) {
          const headerData = defaultHeaderGroupArray.find(
            (headerGroups: ITableHeader) => headerGroups.value === item.value,
          );

          headerGroups.push(headerData);
        }
      }
    });

    if (insertToLast) {
      headers.push(defaultHeaderArray[defaultHeaderArray.length - 1]);
    }

    if (data.setAsDefault && !customSetAsDefault) {
      this.dataTableHelper.onSetAsDefault(pageStore, componentName, columnKeyPropertyName);
    }

    return { headers, widgets, headerGroups };
  }

  public getIsNotBlankFormRule(): ScwMatSelectRule {
    return {
      custom: true,
      message: this.translate.instant('general.isNotBlankValidationMessage'),
      validator: (value) => {
        return !_.isEmpty(String(value).trim());
      },
    };
  }

  public getEditedFields<T>(form: NgForm): Partial<T> {
    let editedFields: Partial<T> = {};
    for (const [key, value] of Object.entries(form.form.controls)) {
      if (value.dirty) {
        editedFields = Object.assign(editedFields, { [key]: value.value });
      }
    }
    return editedFields;
  }

  public static setupPrepareAddEditFormData<T extends IGenericObject<any>>(formData: TFormData<T>) {
    Object.keys(formData.form).forEach((key: string) => {
      this.setNullOrDefaultFormValue(key as keyof T, formData);
      formData.form[key as keyof T].rules = HelperService.cloneDeep(formData.defaultRules[key]);
    });
  }

  public static setEditValuesForFormData<T extends IGenericObject<any>, Y>(
    formData: TFormData<T>,
    selectedItem: Y,
    forOnly?: (keyof T)[],
  ) {
    const existingDataForEdit = HelperService.cloneDeep(selectedItem);

    Object.entries(existingDataForEdit).forEach(([key, value]) => {
      if (!(key in formData.form) || (forOnly?.length && !forOnly.includes(key as keyof T))) {
        return;
      }

      let formValue = value;

      if (key in formData.dropdownOptions) {
        formValue = Array.isArray(value)
          ? value?.map((item) => {
              return { id: item };
            })
          : [{ id: value }];
      }

      formData.form[key].value = formValue;
    });
  }

  public static setFormDataDropdownOptions<T extends IGenericObject<any>>(formData: TFormData<T>) {
    Object.keys(formData.dropdownOptions).forEach((key) => {
      if (key in formData.form && !formData.form[key].isAsync) {
        this.modifyDropdownValueWithItsData(key, formData);
      }
    });
  }

  public static setNullOrDefaultFormValue<T extends IGenericObject<any>>(key: keyof T, formData: TFormData<T>) {
    if ('defaultValue' in formData.form[key]) {
      formData.form[key].value = HelperService.cloneDeep(formData.form[key].defaultValue);

      if (key in formData.dropdownOptions) {
        this.modifyDropdownValueWithItsData(key, formData);
      }

      return;
    }

    formData.form[key].value = null;
  }

  public static modifyDropdownValueWithItsData<T extends IGenericObject<any>>(
    key: keyof T,
    formData: TFormData<T>,
    primaryKey: string = 'id',
  ): T[keyof T]['value'] {
    formData.form[key].value = _.intersectionBy(formData.dropdownOptions[key], formData.form[key].value, primaryKey);

    return formData.form[key].value;
  }

  public static changeFormValueIfNotSame<T extends IGenericObject<any>>(
    $event: any,
    key: keyof T,
    formData: TFormData<T>,
  ): void {
    if (formData.form[key].value !== $event) {
      formData.form[key].value = $event;
    }
  }

  public onApplyGeneralConfiguration(
    data: TGeneralConfigurationSection[],
    defaultHeaders: ITableHeaderGroupItem[],
    defaultWidgets: IWidgetConfiguration[],
    defaultSortOrders: IColumnSortOrderConfiguration[],
    defaultHighlightColumns: IDropdownOptions[],
    pageStore: keyof OeeAppState,
    componentName: ComponentNamesForUserConfiguration,
    columnKeyPropertyName: string = 'name',
  ): IUserConfigurationFromGeneralConfigurationApply {
    const configurationObjects: TGeneralConfigurationSection[] = _.cloneDeep(data);
    const headers: ITableHeaderGroupItem[] = [];
    const widgets: IWidgetConfiguration[] = [];
    const sortOrders: { name: string; items: IColumnSortOrderItem[] }[] = [];
    const highlightColumns: IDropdownOptions[] = [];

    configurationObjects.forEach((configuration: TGeneralConfigurationSection): void => {
      switch (configuration.type) {
        case EConfigurationTypes.COLUMN_SORT_ORDER:
          if (
            defaultSortOrders.some(
              (defaultSortOrder: IColumnSortOrderConfiguration) => defaultSortOrder.name === configuration.name,
            )
          ) {
            sortOrders.push(this.getSelectedSortOrders(configuration));
          }
          break;
        case EConfigurationTypes.WIDGET:
          widgets.push(
            ...this.getSelectedWidgetsFromConfiguration(configuration.items, defaultWidgets, columnKeyPropertyName),
          );
          break;
        case EConfigurationTypes.TABLE:
          headers.push(
            ...this.getSelectedHeadersFromConfiguration(
              configuration.selectedItems,
              defaultHeaders,
              columnKeyPropertyName,
            ),
          );
          break;
        case EConfigurationTypes.TARGET_EXCEEDED_HIGHLIGHT_COLUMNS:
          highlightColumns.push(...configuration.selectedItems);
      }
    });

    return { headers, widgets, sortOrders, highlightColumns };
  }

  private getSelectedSortOrders(
    configuration: IGeneralConfigurationColumnSortOrderSection,
  ): IColumnSortOrderConfiguration {
    return {
      name: configuration.name,
      items: configuration.items,
    };
  }

  private getSelectedWidgetsFromConfiguration(
    configuration: IWidgetConfiguration[],
    defaultWidgets: IWidgetConfiguration[],
    columnKeyPropertyName: string,
  ): IWidgetConfiguration[] {
    return configuration.filter(
      (widget: IWidgetConfiguration) =>
        widget.selected &&
        defaultWidgets.some(
          (defaultWidget: IWidgetConfiguration) => defaultWidget.name === widget[columnKeyPropertyName],
        ),
    );
  }

  private getSelectedHeadersFromConfiguration(
    configuration: ITableHeaderGroupItem[],
    defaultHeaders: ITableHeaderGroupItem[],
    columnKeyPropertyName: string,
  ): ITableHeaderGroupItem[] {
    return configuration.filter((item: ITableHeaderGroupItem) =>
      defaultHeaders.some((defaultHeader: ITableHeaderGroupItem) => defaultHeader.name === item[columnKeyPropertyName]),
    );
  }

  public static setNullIfFormPropertyNotExists<T extends IGenericObject<any>, Y extends IGenericObject<any>>(
    formData: TFormData<T>,
    dataToSubmit: Y,
  ) {
    Object.keys(formData.form).forEach((key: string) => {
      if (key in dataToSubmit) {
        return;
      }

      dataToSubmit[key as keyof Y] = null;
    });
  }
}
