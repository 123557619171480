import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { IActivityComment } from 'src/app/standalone/comment-feed/comment-feed-service/comment-feed.model';
import {
  ActivityFormEnabledFields,
  ActivityFormModel,
  ActivityHistoryBulkUpdateModel,
  ShownFieldsModel,
  UserModel,
} from './activity-form.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as AppActions from '../../../store/app/actions';
import { ActionsSubject, Store } from '@ngrx/store';
import { HelperService } from '../../service/helper.service';
import { activityColors } from '../../helper/app-helper';
import { IOption } from 'ng-select';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { InputLimit } from '../../model/enum/input-limit';
import { ActivityTypes } from '../../model/enum/activity-types';
import { ActivityEditModalDataInterface } from '../../../view/activity-review/activity-review.model';
import {
  ISelectWorkOrderModalConfigurations,
  IWorkOrderCardLayout,
  WorkOrderHeader,
} from '../../../view/home/work-order/work-order.model';
import {
  IPredictedWorkOrderForSplit,
  WoSelectGenericOutputInterface
} from '../select-work-order/select-work-order.model';
import * as HomeActions from '../../../store/home/home.actions';
import * as ActivityHistoryActions from '../../../store/activity-history/activity-history.actions';
import { ofType } from '@ngrx/effects';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { OeeAppState } from '../../../store/oee.reducer';
import {
  FixedAndCurrentVersionInterface,
  OverlappingDataInterface,
  SaveUpdateOrDeleteActivityEventDataInterface,
  SelectedActivityEventDataInterface,
} from '../../../store/activity-history/activity-history.model';
import { filter, startWith, take } from 'rxjs/operators';
import { mysqlTimestampFormat, mysqlTimestampFormatZeroSecond } from '../../helper/date';
import { ActivityTaskUpdateInterface } from '../../../store/activity-button/activity-button.model';
import {
  EquipmentTaskOutputInterface,
  IStartEquipmentTaskParameter,
} from '../../../store/equipment-task/equipment-task.model';
import * as _ from 'lodash';
import {
  DEFAULT_DECIMAL_INPUT_RULE,
  EActivityHistoryTableCalledFrom,
  ELanguages,
  OWL_DATE_TIME_PROVIDERS,
  StatusesEnum,
} from '../../../../constants';
import { PhaseOptions } from '../../model/enum/phase-options';
import { ActivityHistoryState } from '../../../store/activity-history/activity-history.reducer';
import { LineCRUDInterface, SiteCRUDInterface } from '../filter/filter.class';
import { UserGetManyCRUDDataInterface, UserGetOneCRUDDataInterface } from '../../service/user/user.model';
import {
  ScwMatPickerReturnInterface,
  ScwMatPickerType,
  ScwMatPickerTypeEnum,
} from '../scw-mat-ui/scw-mat-picker/scw-mat-picker.model';
import { DropdownOptionInterface } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputComponent } from '../scw-mat-ui/scw-mat-input/scw-mat-input.component';
import { ScwMatSelectComponent } from '../scw-mat-ui/scw-mat-select/scw-mat-select.component';
import { ScwMatTextareaComponent } from '../scw-mat-ui/scw-mat-textarea/scw-mat-textarea.component';
import { ScwMatPickerComponent } from '../scw-mat-ui/scw-mat-picker/scw-mat-picker.component';
import { PhaseSelectionComponent } from '../phase-selection/phase-selection.component';
import { IRootAddress } from '../../../store/settings/tree-nodes/tree-nodes.model';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { ColorService } from '../../service/color/color.service';
import { ComponentColors, CustomColorParameters } from '../../service/color/color.model';
import { SiteInterface } from 'src/app/store/site-line-selection/site-line-selection.model';
import { User, UserLevels } from 'src/app/store/user/model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import * as SitesActions from '../../../store/settings/sites/sites.actions';
import { ISiteRequestParams } from '../../../view/settings/sites/sites.model';
import * as LinesActions from '../../../store/settings/departments-lines-stations/lines/lines.actions';
import { ITableQuery } from '../../../view/settings/departments-lines-stations/lines/lines.model';
import { LinesStateInterface } from '../../../store/settings/departments-lines-stations/lines/lines.model';
import { ISitesStore } from '../../../store/settings/sites/sites.model';
import { IInMemoryComment } from 'src/app/standalone/comment-feed/comment-feed-modal/comment-feed-modal.model';
import { IDynamicComponentDirectiveContext, IGenericObject } from '../../model/interface/generic.model';
import { ScwMatDatepickerComponent } from '../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.component';
import { ActivityHistoryService } from '../../service/activity-history/activity-history.service';
import { IActivityHistory, IWorkOrderOfActivityHistory } from '../../service/activity-history/activity-history.model';
import * as HomePageDisplaySettingsActions from '../../../store/settings/home-page-display-settings/home-page-display-settings.actions';

@OnDestroyDecorator
@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: [
    './activity-form.component.scss',
    '../../../view/activity-history/activity-history.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../../../../../node_modules/sweetalert2/src/sweetalert2.scss',
    '../../../../scss/custom.scss',
  ],
  providers: [OWL_DATE_TIME_PROVIDERS, DatePipe],
})
export class ActivityFormComponent implements OnInit, OnDestroy {
  @ViewChild(PhaseSelectionComponent) phaseSelectComponent: PhaseSelectionComponent;
  @ViewChild('workOrder', { static: true }) workOrderSelectRef: ScwMatPickerComponent;

  @Input() defaultData: ActivityEditModalDataInterface | undefined;
  @Input() closeAllModals: boolean = true;
  @Input() siteData: SiteCRUDInterface[];
  @Input() lineData: LineCRUDInterface[];
  @Input() shownFields: ShownFieldsModel = {
    start: true,
    end: true,
    user: true,
    site: true,
    line: true,
    activity: true,
    task: true,
    workOrderNumber: true,
    crewSize: true,
    workOrderSize: true,
    description: true,
  };
  @Input() enabledFields: Record<ActivityFormEnabledFields, boolean> = {
    line: true,
    site: true,
    workOrder: true,
  };
  @Input() calledFrom!: EActivityHistoryTableCalledFrom;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('overlapping_activity_warning_modal', { static: false })
  overlappingActivitiesModalTemplate: ViewChildDecorator;

  InputLimit = InputLimit;
  private activityHistoryStoreSubscription: Subscription;
  private manual: boolean;
  private saveUpdateOrDeleteActivityEventDataCompletedSubscription: Subscription;
  private searchTaskByActivityIdActionSubscription: Subscription;
  private readonly storeSubscriptions: Subscription[] = [];
  private successMessage: string | any;
  private radix: number = 10;
  public activityHistoryTableCalledFrom: typeof EActivityHistoryTableCalledFrom = EActivityHistoryTableCalledFrom;
  public activities: IOption[] = [];
  public activityFormRules = {
    startDate: [this.helperService.getRequiredFormRule()],
    workOrder: [this.helperService.getRequiredFormRule()],
    activityName: [this.helperService.getRequiredFormRule()],
    task: [this.helperService.getRequiredFormRule()],
    endDate: [this.helperService.getRequiredFormRule()],
    site: [this.helperService.getRequiredFormRule()],
    line: [this.helperService.getRequiredFormRule()],
    user: [this.helperService.getRequiredFormRule()],
    crew: [DEFAULT_DECIMAL_INPUT_RULE],
  };
  public inMemoryComments: IInMemoryComment[] = [];
  public areAnyOfTheBulkEditFormSelectBoxesSelected: string = undefined;
  public activityForm: ActivityFormModel = {
    id: { isEnabled: true, value: null },
    startDate: { isEnabled: true, value: { startDate: null, endDate: null } },
    endDate: { isEnabled: true, value: { startDate: null, endDate: null } },
    startedByUser: { isEnabled: true, value: null },
    endedByUser: { isEnabled: true, value: null },
    site: { isEnabled: true, value: null },
    line: { isEnabled: true, value: null },
    activityType: { isEnabled: true, value: null },
    activityName: { isEnabled: true, value: null },
    activityNameTranslations: { isEnabled: true, value: null },
    task: { isEnabled: true, value: null },
    taskName: { isEnabled: true, value: null },
    taskNameTranslations: { isEnabled: true, value: null },
    workOrder: { isEnabled: true, value: null },
    workOrderSize: { isEnabled: true, value: null },
    workOrderTableId: { isEnabled: true, value: null },
    crewSize: { isEnabled: true, value: null },
    productDescription: { isEnabled: true, value: null },
    productId: { isEnabled: true, value: null },
    phaseId: { isEnabled: true, value: null },
    equipmentName: { isEnabled: true, value: null },
  };
  public allFieldsDisableStatus: boolean;
  public dateTimeFormat$: string;
  public dateTimePlaceHolder: string;
  public workOrderProductText: string = null;
  public selectWorkOrderModalConfigurations: ISelectWorkOrderModalConfigurations = null;
  public workOrderHeader: WorkOrderHeader = {
    woNumber: '',
    productDescription: '',
    productId: '',
    id: 0,
    quantityOrdered: '',
    productName: '',
  };
  public usersAllData: UserModel[] = [];
  public taskControl: boolean;
  public workOrderControl: boolean;
  public defaultDataControl: boolean;
  public isSubmitButtonClicked: boolean;
  public selectedActivityDataLoading$: boolean;
  public selectedActivityType: string = '';
  public lines: DropdownOptionInterface[] = [];
  public sites: DropdownOptionInterface[] = [];
  public tasks: DropdownOptionInterface[] = [];
  public users: DropdownOptionInterface[] = [];
  public workOrderNumberHiddenAsterisk: boolean;
  public workOrderNumberHiddenStatus: boolean;
  public workOrderSizeHiddenStatus: boolean;
  public workOrders: IOption[] = [];
  public activityFormDataSaveLoading$: boolean;
  public deleteInProgress: boolean = false;
  public usersLoading: boolean = false;
  public userPlaceholder: string = null;
  private currentUserOption$: DropdownOptionInterface[] = [];
  private updateInterval: ReturnType<typeof setInterval>;
  public isBulkEditForm: boolean = false;
  private formHasEdited: boolean = false;
  private timezone$: string;
  public language$: ELanguages;
  private userSubscription: Subscription;
  private homeActionSubscription: Subscription;
  private lineSubscription: Subscription;
  private homePageDisplaySettingsSubscription: Subscription;
  public bulkEditLoading$: boolean;
  private activityButtonsModalRef: NgbModalRef;
  private taskButtonsModalRef: NgbModalRef;
  public selectWorkOrderModalRef: NgbModalRef;
  private overlappingActivitiesModalRef: NgbModalRef;
  public isUpdateOperation: boolean = false;
  public activityColor: string = activityColors('default');
  public initialValueOfDateRange: { startDate: string; endDate: string };
  public workOrderPickerInputModel: ScwMatPickerReturnInterface = null;
  public activityPickerInputModel: ScwMatPickerReturnInterface = null;
  public taskPickerInputModel: ScwMatPickerReturnInterface = null;
  public selectedActivityColor: ScwMatPickerType;
  public userId$: string;
  public lineId: number;
  public siteId: number;
  public locale$: string;
  public allLines: LineCRUDInterface[];
  public isPhaseValid: boolean = true;
  public maxDate: moment.Moment = null;
  public activityHistoryDeleteModalRef: NgbModalRef;
  public readonly scwModalSm: string = 'scw-modal-sm';
  public isBulkRunTime: boolean = false;
  public isTaskMissingDataBulk: boolean = false;
  public missingWorkOrderBulk: boolean = false;
  public workOrderPickerLabelTranslation: string = this.translate.instant('activityHistory.form.workOrderNumber');
  public activityTypes = ActivityTypes;
  public taskTreeRootAddress: IRootAddress = { activityId: null, lineId: null };
  public startEquipmentTaskParameters: IStartEquipmentTaskParameter;
  public plannedDownTimeActivityInputColors$: ComponentColors;
  public allSites$: SiteInterface[];
  public customColorParams$: CustomColorParameters;
  public siteChange = new Subject<number>();
  public runWithoutWorkOrder: boolean = false;
  public workOrderName: ScwMatPickerReturnInterface;
  public weekStartDay: number = null;
  public selectedSiteId: number = null;

  public startDatepickerDefaultInputs: IGenericObject<unknown> = {
    label: `${this.translate.instant('general.start')}*`,
    rules: this.activityFormRules.startDate,
    disabled: false,
    singleDatePicker: true,
    timePickerSeconds: true,
    timePicker: true,
    autoApply: true,
    showCustomRangeLabel: false,
    showClearButton: false,
    ranges: null,
    maxDate: this.activityForm.endDate.value?.startDate ?? this.maxDate,
    inputModel: this.activityForm.startDate.value,
    showCalendarIcon: true,
    isCustomWeekStart: true,
    weekStartDay: this.weekStartDay,
  };

  public endDatepickerDefaultInputs: IGenericObject<unknown> = {
    ...this.startDatepickerDefaultInputs,
    label: `${this.translate.instant('general.end')}*`,
    rules: this.activityFormRules.endDate,
    maxDate: this.maxDate,
    minDate: this.activityForm.startDate.value?.startDate,
    inputModel: this.activityForm.endDate.value,
  };

  public startDateContext: IDynamicComponentDirectiveContext = {
    value: this.selectedSiteId,
    customElement: {
      type: ScwMatDatepickerComponent,
      inputValues: this.startDatepickerDefaultInputs,
    },
  };

  public endDateContext: IDynamicComponentDirectiveContext = {
    value: this.selectedSiteId,
    customElement: {
      type: ScwMatDatepickerComponent,
      inputValues: this.endDatepickerDefaultInputs,
    },
  };
  private startDatePickerSub: Subscription;
  private startDatePickerInstance: ScwMatDatepickerComponent;
  private endDatePickerSub: Subscription;
  private endDatePickerInstance: ScwMatDatepickerComponent;
  private hasDefaultValuesForWorkOrder: boolean = false;
  public workOrderCardLayout: IWorkOrderCardLayout = null;
  public predictedWorkOrderForSplit: IPredictedWorkOrderForSplit | null = null;

  constructor(
    private readonly ngbModal: NgbModal,
    public helperService: HelperService,
    private translate: TranslateService,
    private activityHistoryService: ActivityHistoryService,
    private http: HttpClient,
    private store: Store<OeeAppState>,
    private pipeDate: DatePipe,
    private toast: ToastrService,
    private homeActions: ActionsSubject,
    private homePageDisplaySettingsActions: ActionsSubject,
    private readonly decimalHelper: DecimalHelper,
    private colorService: ColorService,
  ) {
    this.store.dispatch(new ActivityHistoryActions.ClearFormDataStatus());
    this.usersAllData = [];
    this.taskControl = false;
    this.workOrderControl = false;
    this.defaultDataControl = false;
    this.allFieldsDisableStatus = false;
    this.startDatepickerDefaultInputs['disabled'] = this.allFieldsDisableStatus;
    this.endDatepickerDefaultInputs['disabled'] = this.allFieldsDisableStatus;
    this.workOrderNumberHiddenStatus = false;
    this.workOrderSizeHiddenStatus = false;
    this.workOrderNumberHiddenAsterisk = true;
    this.isSubmitButtonClicked = false;
    this.resetForm();
  }

  public submitForm(isValid: boolean): void {
    this.formHasEdited = true;
    if (this.phaseSelectComponent) {
      this.checkPhaseDropdown();
    }

    const areDatePickersValid: boolean = this.isBulkEditForm ? true : this.areDatePickersValid();

    if (!isValid || !this.isPhaseValid || !areDatePickersValid) {
      return;
    }

    this.isSubmitButtonClicked = true;

    const [startDate, endDate] = this.getStartEndDate();

    if (startDate === endDate && !this.isBulkEditForm) {
      this.toast.error(
        this.translate.instant('activityHistory.form.activityStartEndCannotBeSame'),
        this.translate.instant('general.error'),
        {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-right',
        },
      );
      return;
    }

    if (this.isBulkEditForm) {
      this.sendData();
    } else {
      this.validateActivityOverlap();
    }
  }

  public getActivityFormData(): void {
    this.storeSubscriptions.push(
      this.store.select('linesStore').subscribe((state: LinesStateInterface) => {
        if (this.lineData || !state.linesDataLoaded || state.linesDataLoading) {
          if (!state.linesDataLoading) {
            this.store.dispatch(new AppActions.HideLoader());
          }

          return;
        }
        this.allLines = state.lines;
        this.selectWorkOrderModalConfigurations.lineType = this.allLines.find(
          (line: LineCRUDInterface) => line.id === this.lineId,
        )?.lineType;
        this.lines = state.lines
          .filter((line: LineCRUDInterface) => line.statusId === StatusesEnum.ACTIVE && line.siteId === this.siteId)
          .map((line: LineCRUDInterface) => {
            return {
              id: line.id,
              name: line.title,
            };
          });
        this.activityForm.line.value =
          this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS && this.lineData?.length
            ? []
            : [
                _.find(this.lines, {
                  id: this.lineId,
                }),
              ];
        this.store.dispatch(new AppActions.HideLoader());
      }),
      this.store.select('sitesStore').subscribe((state: ISitesStore) => {
        if (this.siteData || !state.isSitesDataLoaded || state.isSitesDataLoading) {
          return;
        }

        this.sites = state.sitesData
          .filter((site: SiteCRUDInterface) => site.statusId === StatusesEnum.ACTIVE || site.id === this.siteId)
          .map((site: SiteCRUDInterface) => {
            return {
              id: site.id,
              name: site.name,
            };
          });
        this.activityForm.site.value = [_.find(this.sites, { id: this.siteId })];
      }),

      combineLatest([
        this.store.select('user').pipe(filter((value) => value.isUserLoaded === true)),
        this.store.select('sitesStore').pipe(
          filter((value) => value.isSitesDataLoaded),
          startWith({ sitesData: this.siteData }),
          filter((value) => value.sitesData !== undefined && value.sitesData !== null),
        ),
        this.siteChange.pipe(startWith(this.activityForm.site.value[0]?.id)),
      ]).subscribe(([user, sites, selectedSiteId]: [User, ISitesStore, number]) => {
        const siteId: number | undefined = selectedSiteId ?? user.siteId;
        this.selectedSiteId = siteId;
        this.allSites$ = sites.sitesData;
        this.customColorParams$ = {
          allSites: this.allSites$,
          client: user.clientInfo,
          visibleSiteIds: siteId ? [siteId] : [],
        };
        this.plannedDownTimeActivityInputColors$ = this.colorService.pickComponentColors(
          'plannedDownTimeActivityInput',
          this.customColorParams$,
        );
        this.weekStartDay = this.allSites$.find((site: SiteInterface) => site.id === siteId).weekStartDay;
        this.startDateContext = this.updateDatepickerContext(this.startDateContext, true);
        this.endDateContext = this.updateDatepickerContext(this.endDateContext, false);
      }),
    );

    if (this.isBulkEditForm) {
      return;
    }

    this.homeActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.SearchUsersByFilterCompleted))
      .subscribe((payload: UserGetManyCRUDDataInterface) => {
        this.usersLoading = false;
        if (payload.data.length) {
          const data: UserGetOneCRUDDataInterface[] = payload.data;
          this.activityForm.startedByUser.value = '';
          this.activityForm.endedByUser.value = '';
          this.users = [];

          for (const item of data) {
            this.users.push({
              id: String(item.id),
              name: item.fullName,
            });
          }
        } else {
          this.users = [];
          this.activityForm.startedByUser.value = '';
          this.activityForm.endedByUser.value = '';
          this.userPlaceholder = this.translate.instant('cico.modules.laborTracker.searchDropdown.noResultMessage');
        }
      });
  }

  private getStartEndDate(): string[] {
    let startDate: string = moment(
      this.activityForm.startDate.value.startDate,
      this.dateTimeFormat$,
      this.locale$,
    ).format(mysqlTimestampFormat);
    let endDate: string = moment(this.activityForm.endDate.value.endDate, this.dateTimeFormat$, this.locale$).format(
      mysqlTimestampFormat,
    );

    if (moment.isMoment(this.activityForm.startDate.value) && moment.isMoment(this.activityForm.endDate.value)) {
      startDate = moment(this.activityForm.startDate.value).format(mysqlTimestampFormat);
      endDate = moment(this.activityForm.endDate.value).format(mysqlTimestampFormat);
    }

    if (this.helperService.isTouchDevice && !this.isBulkEditForm) {
      this.helperService.checkIsValidDateOrFail(startDate, mysqlTimestampFormat);
      this.helperService.checkIsValidDateOrFail(endDate, mysqlTimestampFormat);
    }

    return [startDate, endDate];
  }

  public getLines(manuel = false): void {
    if (!this.activityForm?.site?.value?.length) {
      this.activityForm.line.value = [];
      this.activityForm.startedByUser.value = '';
      this.activityForm.endedByUser.value = '';
      this.clearSelectedActivityAndTask();
      this.clearWorkOrder();
      return;
    }

    if (manuel) {
      this.activityForm.startedByUser.value = '';
      this.activityForm.endedByUser.value = '';
      this.users = [];
      this.activityForm.line.value = [];
      this.clearSelectedActivityAndTask();
      this.clearWorkOrder();
      this.filterLines();
    } else if (this.defaultData === null) {
      this.users = this.currentUserOption$;
    }

    this.workOrderControl = this.defaultData !== null && _.get(this.defaultData, 'workOrder.completed', 0) === 1;
    this.startDateContext = this.updateDatepickerContext(this.startDateContext, true);
    this.endDateContext = this.updateDatepickerContext(this.endDateContext, false);
  }

  private setTaskIfActivityIsRun(): string {
    if (this.activityForm.workOrder !== null) {
      this.activityForm.task.value = null;
      this.activityForm.taskName.value = this.activityForm.productDescription.value;
    }

    return this.activityForm.productDescription.value;
  }

  sendData(): void {
    this.formHasEdited = true;

    if (this.isBulkEditForm) {
      const data: ActivityHistoryBulkUpdateModel[] = [];
      for (const id of _.get(this.defaultData, 'bulkEdit.selectedBulkActivities', [])) {
        const activityHistory: ActivityHistoryBulkUpdateModel = {
          id: parseInt(id, this.radix),
        };

        if (this.activityForm.activityName.isEnabled) {
          activityHistory.activityId = parseInt(String(this.activityForm.activityType.value), this.radix);
          activityHistory.taskId = parseInt(this.activityForm.task.value, this.radix);
          activityHistory.phaseId = Number(this.activityForm.phaseId.value) || null;
        }

        if (this.activityForm.crewSize.isEnabled) {
          activityHistory.crewSize = this.decimalHelper.sanitizeString(this.activityForm.crewSize.value);
        }

        if (this.activityForm.workOrder.isEnabled) {
          activityHistory.workOrderId =
            this.activityForm.workOrderTableId.value === undefined ? null : this.activityForm.workOrderTableId.value;

          if (!_.isNil(this.predictedWorkOrderForSplit)) {
            activityHistory.workOrderToSplitInfo = this.predictedWorkOrderForSplit;
          }
        }

        data.push(activityHistory);
      }

      const numberOfSuccessfulActivities = this.defaultData.bulkEdit.selectedBulkActivities.length.toString();
      this.successMessage = `${numberOfSuccessfulActivities} ${this.translate.instant(
        'activityReview.messages.bulkEditSuccessMessage',
      )}`;

      this.store.dispatch(
        new ActivityHistoryActions.SaveOrUpdateBulkActivityEventData({
          activityHistories: data,
        }),
      );
    } else {
      const [startDate, endDate] = this.getStartEndDate();
      const activityData: SaveUpdateOrDeleteActivityEventDataInterface = {
        start: startDate,
        end: endDate,
        siteId: this.activityForm.site.value[0].id,
        lineId: this.activityForm.line.value[0].id,
        activityId: parseInt(String(this.activityForm.activityType.value), this.radix),
        taskId: this.activityForm.task ? parseInt(this.activityForm.task.value, this.radix) : null,
        crewSize: this.decimalHelper.sanitizeString(this.activityForm.crewSize.value),
        workOrderId: this.activityForm.workOrderTableId.value,
        phaseId:
          this.activityForm.phaseId.value === PhaseOptions.NO_PHASE ? null : Number(this.activityForm.phaseId.value),
        comments: this.inMemoryComments,
      };

      if (!_.isNil(this.predictedWorkOrderForSplit)) {
        activityData.workOrderToSplitInfo = this.predictedWorkOrderForSplit;
      }

      if (this.defaultData !== null) {
        activityData.id = this.activityForm.id.value;
        this.successMessage = this.translate.instant('activityHistory.form.updateSuccessMessage');
      } else {
        this.successMessage = this.translate.instant('activityHistory.form.createSuccessMessage');
      }
      this.store.dispatch(new ActivityHistoryActions.SaveUpdateOrDeleteActivityEventData({ ...activityData }));
    }

    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new ActivityHistoryActions.SetOverlapActivityParametersDefault());
  }

  deleteData(): void {
    if (!this.isBulkEditForm) {
      this.store.dispatch(
        new ActivityHistoryActions.SaveUpdateOrDeleteActivityEventData(null, this.activityForm.id.value),
      );
    } else {
      this.store.dispatch(
        new ActivityHistoryActions.DeleteManyActivityHistoryData(this.defaultData.bulkEdit.selectedBulkActivities),
      );
    }
  }

  ngOnInit(): void {
    this.isSubmitButtonClicked = false;
    const options: ISiteRequestParams | ITableQuery = {
      page: 1,
      pageSize: 500,
    };

    this.userSubscription = this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state) => {
        this.timezone$ = state.timezone;
        this.language$ = state.language as ELanguages;
        this.maxDate = moment(moment().tz(this.timezone$).format(mysqlTimestampFormat));
        this.startDatepickerDefaultInputs['maxDate'] = this.activityForm.endDate.value?.startDate ?? this.maxDate;
        this.endDatepickerDefaultInputs['maxDate'] = this.maxDate;

        if (state.userId) {
          this.userId$ = state.userId;
          this.siteId = this.defaultData ? this.defaultData.siteId : state.siteId;
          this.lineId = this.defaultData ? this.defaultData.lineId : state.lineId;
          this.dateTimeFormat$ = state.dateTimeFormat;
          this.locale$ = state.locale;
          this.currentUserOption$ = [
            {
              id: state.userId,
              name: state.fullName,
            },
          ];
        }

        this.selectWorkOrderModalConfigurations = {
          siteName: state.siteName,
          siteId: this.siteId,
          lineId: this.lineId,
          lineType: null,
        };
      });
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.lineId) {
      this.store.dispatch(new HomePageDisplaySettingsActions.GetLayoutCardTemplate(this.lineId));
    }

    if (this.defaultData) {
      this.activityForm.site.value = [{ id: this.defaultData.siteId, name: '' }];
      this.activityForm.line.value = [{ id: this.defaultData.lineId, name: '' }];
      this.activityForm.startDate.value = {
        startDate: moment(this.defaultData.start, this.dateTimeFormat$, this.locale$),
        endDate: moment(this.defaultData.start, this.dateTimeFormat$, this.locale$),
      };
      this.activityForm.endDate.value = {
        startDate: moment(this.defaultData.end, this.dateTimeFormat$, this.locale$),
        endDate: moment(this.defaultData.end, this.dateTimeFormat$, this.locale$),
      };
      this.activityForm.phaseId.value = String(this.defaultData.phaseId);
      this.initialValueOfDateRange = {
        startDate: _.clone(this.defaultData.start),
        endDate: _.clone(this.defaultData.end),
      };
    } else {
      this.activityForm.site = {
        isEnabled: true,
        value: [{ id: this.siteId, name: '' }],
        rules: this.activityFormRules.site,
      };
      this.activityForm.line = {
        isEnabled: true,
        value: [{ id: this.lineId, name: '' }],
        rules: this.activityFormRules.line,
      };
      this.activityForm.startedByUser = {
        isEnabled: false,
        value: this.currentUserOption$?.[0]?.name,
      };
      this.activityForm.endedByUser = {
        isEnabled: false,
        value: this.currentUserOption$?.[0]?.name,
      };
    }

    const defaultValuesForWorkOrder: Partial<IActivityHistory> =
      this.activityHistoryService.timeEntryModalWorkOrdersSubject.getValue();
    this.hasDefaultValuesForWorkOrder = !_.isNil(defaultValuesForWorkOrder);

    if (defaultValuesForWorkOrder?.workOrder) {
      this.setActivityDataValues(defaultValuesForWorkOrder);
      this.setWorkOrderPickerValue(
        this.activityForm.workOrder.value,
        this.activityForm.productId.value,
        this.activityForm.productDescription.value,
      );
      _.set(this.enabledFields, 'workOrder', false);
    }

    if (this.defaultData?.workOrder) {
      _.set(this.enabledFields, 'workOrder', false);
    }

    if (!this.siteData) {
      this.store.dispatch(new SitesActions.LoadSitesData(options));
    } else {
      this.sites = this.siteData;
      _.set(this.enabledFields, 'site', false);
      this.activityForm.site.value = [
        _.find(this.sites, { id: this.defaultData?.siteId || defaultValuesForWorkOrder?.siteId }),
      ];
    }

    if (!this.lineData) {
      this.store.dispatch(new LinesActions.LinesDataLoad(options));
    } else {
      this.allLines = this.lineData;
      _.set(this.enabledFields, 'line', true);
      this.lines = this.lineData
        .filter(
          (line: LineCRUDInterface): boolean =>
            line.statusId === StatusesEnum.ACTIVE &&
            line.siteId === (this.defaultData?.siteId || defaultValuesForWorkOrder?.siteId),
        )
        .map((line: LineCRUDInterface) => {
          return {
            id: line.id,
            name: line.title,
          };
        });

      if (this.defaultData?.lineId || defaultValuesForWorkOrder?.lineId) {
        this.activityForm.line.value = [
          _.find(this.lines, { id: this.defaultData?.lineId || defaultValuesForWorkOrder?.lineId }),
        ];
        _.set(this.enabledFields, 'line', false);
      } else if (
        defaultValuesForWorkOrder?.workOrder &&
        defaultValuesForWorkOrder?.siteId &&
        this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
      ) {
        this.activityForm.line.value = [];
      }
    }

    this.dateTimePlaceHolder = this.helperService.getPlaceholderForDate(true);

    this.isBulkEditForm = this.defaultData !== null && this.defaultData.hasOwnProperty('bulkEdit');
    if (this.isBulkEditForm) {
      this.prepareBulkFormData();
    }
    this.isTaskMissingDataBulk = this.isBulkEditForm && this.defaultData.bulkEdit.taskMissingData;
    this.allFieldsDisableStatus = Boolean(
      this.defaultData !== null && this.defaultData.workOrder && this.defaultData.workOrder.completed === 1,
    );
    this.startDatepickerDefaultInputs['disabled'] = this.allFieldsDisableStatus;
    this.endDatepickerDefaultInputs['disabled'] = this.allFieldsDisableStatus;

    this.getActivityFormData();

    this.activityHistoryStoreSubscription = this.store
      .select('activityHistoryStore')
      .subscribe((state: ActivityHistoryState) => {
        this.selectedActivityDataLoading$ = state.selectedActivityDataLoading;
        this.activityFormDataSaveLoading$ = state.activityFormDataSaveLoading;
        this.bulkEditLoading$ = state.bulkEditLoading;

        const openOverlapActivityModal: boolean =
          state.overlapActivityDataLoaded && state.overlappedActivityData.hasAnyChange;
        const hasNotOverlapActivity: boolean =
          !state.activityFormDataSaveLoading &&
          state.overlapActivityDataLoaded &&
          !state.overlappedActivityData.hasAnyChange;

        if (openOverlapActivityModal) {
          this.openOverlappingActivityModal();
        } else if (hasNotOverlapActivity) {
          this.sendData();
        }

        if (
          !state.overlapActivityDataLoaded &&
          !state.overlapActivityDataLoading &&
          !this.formHasEdited &&
          !this.isBulkEditForm
        ) {
          this.prepareFormData(this.defaultData);
        }

        if (
          !state.bulkEditLoading &&
          !this.isBulkEditForm &&
          !state.activityFormDataSaveLoading &&
          !state.activityFormDataSaveLoaded &&
          !state.overlapActivityDataLoaded &&
          !state.overlapActivityDataLoading
        ) {
          if (this.isUpdateOperation && !this.formHasEdited && state.selectedActivityData) {
            const activityData: ActivityEditModalDataInterface = HelperService.cloneDeep(state.selectedActivityData);
            this.setActivityDataValues(HelperService.cloneDeep(state.selectedActivityData));
            this.workOrderHeader = {
              productId: this.activityForm.productId.value,
              productName: this.activityForm.productId.value,
              productDescription: this.activityForm.productDescription.value,
              quantityOrdered: this.activityForm.workOrderSize.value,
              id: this.activityForm.workOrderTableId.value,
              woNumber: this.activityForm.workOrder.value,
            };
            this.setWorkOrderPickerValue(
              this.activityForm.workOrder.value,
              this.activityForm.productId.value,
              this.activityForm.productDescription.value,
            );
            this.setTaskPickerValue(this.activityForm.taskName.value, this.activityForm.equipmentName.value);
            this.setActivityPickerValue(this.activityForm.activityName.value, this.workOrderSelectRef);

            this.activityForm.startedByUser.value = activityData.user ? activityData.user.fullName : null;
            this.activityForm.endedByUser.value = activityData.createdByUser?.fullName ?? null;

            if (activityData.user) {
              this.users = [{ name: activityData.user.fullName, id: activityData.user.id }];
            }

            if (this.selectedActivityType === ActivityTypes.RUN_TIME) {
              this.activityForm.taskName.value =
                this.activityForm.productId !== null ? activityData.workOrder?.product.description : null;
            }
          }

          if (this.activityForm.site !== null) {
            this.getLines();
          }
        }
      });

    this.searchTaskByActivityIdActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.SearchTaskByActivityIdCompleted))
      .subscribe((response) => {
        this.tasks = [];
        this.taskControl = true;
        const rows: [] = response['payload']['rows'];
        if (rows !== undefined) {
          for (let i = 0; i < rows.length; i = i + 1) {
            this.tasks.push({
              name: `${response['payload']['rows'][i]['id']}`,
              id: response['payload']['rows'][i]['title'],
            });
          }
        }

        this.setTaskIfActivityIsRun();

        if (!this.manual && this.taskControl && this.workOrderControl && this.defaultDataControl) {
          this.store.dispatch(new AppActions.HideLoader());
        }
      });

    this.saveUpdateOrDeleteActivityEventDataCompletedSubscription = this.homeActions
      .pipe(
        ofType(
          ActivityHistoryActions.ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted,
          ActivityHistoryActions.ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted,
          ActivityHistoryActions.ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlapCompleted,
        ),
      )
      .subscribe(() => {
        this.deleteInProgress = false;
        this.activityButtonsModalRef?.close();
        this.taskButtonsModalRef?.close();
        this.selectWorkOrderModalRef?.close();
        this.overlappingActivitiesModalRef?.close();
        this.store.dispatch(new ActivityHistoryActions.SetOverlapActivityParametersDefault());
        this.store.dispatch(new AppActions.HideLoader());
      });

    this.homePageDisplaySettingsSubscription = this.homePageDisplaySettingsActions
      .pipe(ofType(HomePageDisplaySettingsActions.GET_LAYOUT_CARD_TEMPLATE_COMPLETED))
      .subscribe((state: HomePageDisplaySettingsActions.GetLayoutCardTemplateCompleted) => {
        this.workOrderCardLayout = HelperService.cloneDeep(state?.payload?.data[0]?.meta);
        if (
          this.workOrderCardLayout?.textTop &&
          this.workOrderCardLayout?.textMiddle &&
          this.workOrderCardLayout?.textBottom
        ) {
          this.workOrderCardLayout.textTop.field = _.camelCase(this.workOrderCardLayout?.textTop?.field);
          this.workOrderCardLayout.textMiddle.field = _.camelCase(this.workOrderCardLayout?.textMiddle?.field);
          this.workOrderCardLayout.textBottom.field = _.camelCase(this.workOrderCardLayout?.textBottom?.field);
        }
      });
  }

  private prepareFormData(payload: ActivityEditModalDataInterface): void {
    if (payload !== null) {
      this.setTaskTreeRootAddress(payload.activityId);

      this.activityForm = {
        ...this.activityForm,
        id: { isEnabled: true, value: payload.id, rules: [] },
        startDate: {
          isEnabled: true,
          value: { startDate: moment(payload.start), endDate: moment(payload.start) },
          rules: this.activityFormRules.startDate,
        },
        endDate: {
          isEnabled: true,
          value: { startDate: moment(payload.end), endDate: moment(payload.end) },
          rules: this.activityFormRules.endDate,
        },
        equipmentName: { isEnabled: true, value: _.get(payload, 'task.equipment.equipmentName', null), rules: [] },
        startedByUser: {
          isEnabled: false,
          value: payload.user?.fullName,
        },
        endedByUser: {
          isEnabled: false,
          value: payload.createdByUser?.fullName,
        },
        activityType: { isEnabled: true, value: payload.activityId, rules: [] },
        activityName: { isEnabled: true, value: null, rules: this.activityFormRules.activityName },
        activityNameTranslations: { isEnabled: true, value: null, rules: [] },
        task: { isEnabled: true, value: String(payload.taskId), rules: [] },
        taskName: { isEnabled: true, value: null, rules: this.activityFormRules.task },
        taskNameTranslations: { isEnabled: true, value: null, rules: [] },
        productId: {
          isEnabled: true,
          value: payload.workOrder ? String(payload.workOrder.product.productId) : undefined,
          rules: [],
        },
        productDescription: {
          isEnabled: true,
          value: payload.workOrder ? String(payload.workOrder.product.description) : undefined,
          rules: [],
        },
        workOrder: {
          isEnabled: true,
          value: payload.batchNumber,
          rules: payload.activity.activityType === ActivityTypes.IDLE_TIME ? [] : this.activityFormRules.workOrder,
        },
        workOrderSize: { isEnabled: true, value: null, rules: [] },
        crewSize: { isEnabled: true, value: this.decimalHelper.replaceDecimalSeparator(payload.crewSize), rules: [] },
      };
    } else {
      this.activityForm = {
        ...this.activityForm,
        startDate: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        endDate: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        equipmentName: { isEnabled: true, value: null, rules: [] },
        activityType: { isEnabled: true, value: null, rules: [] },
        activityName: { isEnabled: true, value: null, rules: this.activityFormRules.activityName },
        activityNameTranslations: { isEnabled: true, value: null, rules: [] },
        task: { isEnabled: true, value: null, rules: [] },
        taskName: { isEnabled: true, value: null, rules: this.activityFormRules.task },
        taskNameTranslations: { isEnabled: true, value: null, rules: [] },
        productId: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        productDescription: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        workOrder: this.hasDefaultValuesForWorkOrder
          ? this.activityForm.workOrder
          : { isEnabled: true, value: null, rules: this.activityFormRules.workOrder },
        workOrderSize: { isEnabled: true, value: null, rules: [] },
        crewSize: { isEnabled: true, value: null, rules: [] },
        phaseId: { isEnabled: true, value: null, rules: [] },
      };
    }
    this.isUpdateOperation = this.activityForm.id.value !== null;
  }

  public prepareBulkFormData(): void {
    this.activityForm = {
      site: {
        isEnabled: false,
        value: [],
        rules: [],
      },
      line: {
        isEnabled: false,
        value: [],
        rules: [],
      },
      startDate: {
        isEnabled: false,
        value: { startDate: null, endDate: null },
        rules: [],
      },
      endDate: {
        isEnabled: false,
        value: { startDate: null, endDate: null },
        rules: [],
      },
      equipmentName: { isEnabled: false, value: null, rules: [] },
      startedByUser: {
        isEnabled: false,
        value: '',
      },
      endedByUser: {
        isEnabled: false,
        value: '',
      },
      activityType: { isEnabled: false, value: null, rules: [] },
      activityName: { isEnabled: false, value: null, rules: [] },
      activityNameTranslations: { isEnabled: false, value: null, rules: [] },
      task: { isEnabled: false, value: null, rules: [] },
      taskName: { isEnabled: false, value: null, rules: [] },
      taskNameTranslations: { isEnabled: false, value: null, rules: [] },
      productId: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      productDescription: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      workOrder: { isEnabled: false, value: null, rules: [] },
      workOrderSize: { isEnabled: false, value: null, rules: [] },
      workOrderTableId: { isEnabled: false, value: null, rules: [] },
      crewSize: { isEnabled: false, value: null, rules: [] },
      phaseId: { isEnabled: false, value: null, rules: [] },
    };

    if (this.defaultData && this.defaultData.bulkEdit.taskMissingData) {
      this.activityForm.activityName.isEnabled = true;
      _.set(this.activityForm, 'activityName.rules', _.get(this.activityFormRules, 'activityName', []));
    }

    if (this.defaultData && this.defaultData.bulkEdit.woMissingData) {
      this.activityForm.workOrder.isEnabled = true;
      this.missingWorkOrderBulk = true;
      _.set(this.activityForm, 'workOrder.rules', _.get(this.activityFormRules, 'workOrder', []));
    }

    if (this.activityForm.activityName.isEnabled || this.activityForm.workOrder.isEnabled) {
      this.areAnyOfTheBulkEditFormSelectBoxesSelected = 'activityName';
    }
  }

  editedActivityButton($event: ActivityTaskUpdateInterface, formComponentInstance: ScwMatPickerComponent): void {
    this.isBulkRunTime = false;
    this.activityButtonsModalRef.dismiss();
    this.activityForm.activityType.value = $event.activityId;
    this.activityForm.activityName.value = $event.activityName;
    this.activityForm.phaseId.value = $event.activityPhase.toString();
    this.activityForm.activityNameTranslations.value = $event.activityNameTranslations;
    this.selectedActivityType = $event.activityType;
    this.activityForm.phaseId.value = $event.activityPhase.toString();
    this.setTaskTreeRootAddress($event.activityId);

    if (typeof $event.taskName !== 'undefined' && $event.taskName !== null && $event.taskName !== '') {
      this.activityForm.taskName.value = $event.taskName;
      this.activityForm.task.value = $event.taskId.toString();
      this.activityForm.taskNameTranslations.value = $event.taskNameTranslations;
    }

    if (typeof $event.equipmentName !== 'undefined' && $event.equipmentName !== null && $event.equipmentName !== '') {
      this.activityForm.equipmentName.value = $event.equipmentName;
    }

    if ($event.activityType === ActivityTypes.RUN_TIME) {
      this.activityForm.workOrder.isEnabled = true;
      _.set(this.activityForm, 'workOrder.rules', _.get(this.activityFormRules, 'workOrder', []));
      this.activityForm.taskName.isEnabled = false;
      this.taskPickerInputModel = { value: null, type: ScwMatPickerTypeEnum.DEFAULT, text: null };
      this.isBulkRunTime = true;
      if (!this.isBulkEditForm) {
        this.setTaskPickerValue($event.taskName, $event.equipmentName);
      }
    } else {
      this.setTaskPickerValue($event.taskName, $event.equipmentName);
    }
    this.setActivityPickerValue($event.activityName, formComponentInstance);

    this.runWithoutWorkOrder = $event.withoutWorkOrder;

    if (
      this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS &&
      this.isBulkRunTime &&
      this.defaultData.workOrder
    ) {
      const workOrder: IWorkOrderOfActivityHistory = this.defaultData.workOrder;

      this.afterSelectWorkOrder({
        newWorkOrderHeader: {
          id: workOrder.id,
          woNumber: workOrder.woNumber,
          productId: workOrder.product.productId,
          productDescription: workOrder.product.description,
          productName: workOrder.product.productId,
          quantityOrdered: workOrder.quantityOrdered,
        },
        workOrderProductText: `${workOrder.woNumber} - ${workOrder.product.productId} ${workOrder.product.description}`,
      });
    }
  }

  activityUpdateModal(content: TemplateRef<any>): void {
    if (this.activityForm.line === null) {
      this.toast.warning(
        this.translate.instant('activityHistory.form.activityCannotSelect'),
        this.translate.instant('general.warning'),
        {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-right',
        },
      );
      return;
    }

    this.activityButtonsModalRef = this.ngbModal.open(content, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  public editedEquipmentAndTask($event: EquipmentTaskOutputInterface): void {
    this.activityForm.taskName.value = $event.taskName;
    this.activityForm.equipmentName.value = $event.equipmentName;
    this.activityForm.phaseId.value = !_.isNil($event.phaseId)
      ? String($event.phaseId)
      : this.activityForm.phaseId.value;
    this.activityForm.task.value = _.isNil($event.taskId) ? null : $event.taskId.toString();
    this.activityForm.taskNameTranslations.value = $event.taskNameTranslations;
    this.runWithoutWorkOrder = Boolean($event.meta.withoutWorkOrder);
    let taskEquipmentString: string = $event.taskName;

    if ($event.equipmentName) {
      taskEquipmentString = `${$event.taskName} - ${$event.equipmentName}`;
    }

    if (this.selectedActivityType === ActivityTypes.RUN_TIME) {
      this.selectedActivityColor = 'run-time';
    } else if (this.selectedActivityType === ActivityTypes.DOWN_TIME) {
      this.selectedActivityColor = 'unplanned-down-time';
    } else if (this.selectedActivityType === ActivityTypes.DOWN_TIME_PLANNED) {
      this.selectedActivityColor = 'planned-down-time';
    }
    this.taskPickerInputModel = {
      value: taskEquipmentString,
      type: ScwMatPickerTypeEnum.DEFAULT,
      text: taskEquipmentString,
    };
    this.taskButtonsModalRef.dismiss();
  }

  taskUpdateModal(content: TemplateRef<any>): boolean {
    if (this.selectedActivityType === ActivityTypes.RUN_TIME) {
      this.toast.warning(
        this.translate.instant('activityHistory.form.taskCannotChange'),
        this.translate.instant('general.warning'),
        {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-right',
        },
      );
      return false;
    }

    this.startEquipmentTaskParameters = {
      activityId: this.activityForm.activityType.value,
    };

    this.taskButtonsModalRef = this.ngbModal.open(content, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });

    return true;
  }

  private clearWorkOrder(): void {
    this.activityForm.workOrder.value = null;
    this.workOrderHeader = {
      woNumber: '',
      productDescription: '',
      productId: '',
      id: 0,
      quantityOrdered: '',
      productName: '',
    };
    this.workOrderPickerInputModel = null;
  }

  private clearSelectedActivityAndTask(): void {
    const defaultValue = null;
    this.activityForm.activityType.value = defaultValue;
    this.activityForm.activityName.value = defaultValue;
    this.activityForm.activityNameTranslations.value = defaultValue;
    this.activityForm.task.value = defaultValue;
    this.activityForm.taskName.value = defaultValue;
    this.activityForm.taskNameTranslations.value = defaultValue;
    this.activityForm.equipmentName.value = defaultValue;
    this.activityForm.phaseId.value = defaultValue;
    this.selectedActivityType = defaultValue;
    this.taskPickerInputModel = defaultValue;
    this.activityPickerInputModel = defaultValue;
    this.activityColor = activityColors('default');
  }

  public openSelectWorkOrderModal(content): void {
    if (this.activityForm.line.value === null || this.activityForm.line.value.length === 0) {
      this.toast.warning(
        this.translate.instant('activityHistory.form.woCannotSelect'),
        this.translate.instant('general.warning'),
        {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-right',
        },
      );
      return;
    }

    const formSite = this.activityForm?.site?.value[0];
    const formLine = this.activityForm?.line?.value[0];
    this.selectWorkOrderModalConfigurations = {
      siteId: formSite?.id,
      siteName: formSite?.name,
      lineId: formLine?.id,
      lineType: _.find(this.allLines, { id: formLine?.id })?.lineType,
    };

    this.selectWorkOrderModalRef = this.ngbModal.open(content, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  public afterSelectWorkOrder(newWorkOrder: WoSelectGenericOutputInterface, modalToClose: NgbModalRef = null): void {
    this.allFieldsDisableStatus = false;
    this.startDatepickerDefaultInputs['disabled'] = this.allFieldsDisableStatus;
    this.endDatepickerDefaultInputs['disabled'] = this.allFieldsDisableStatus;
    this.activityForm.workOrder.value = newWorkOrder.newWorkOrderHeader.woNumber;
    this.activityForm.productDescription.value = newWorkOrder.newWorkOrderHeader.productDescription;
    this.activityForm.productId.value = newWorkOrder.newWorkOrderHeader.productId;
    this.activityForm.workOrderTableId.value = newWorkOrder.newWorkOrderHeader.id;
    this.activityForm.workOrderSize.value = newWorkOrder.newWorkOrderHeader.quantityOrdered;
    this.workOrderPickerInputModel = {
      text: newWorkOrder.workOrderProductText,
      value: newWorkOrder.newWorkOrderHeader.woNumber,
    };
    this.workOrderHeader = newWorkOrder.newWorkOrderHeader;
    this.workOrderProductText = newWorkOrder.workOrderProductText;
    this.selectWorkOrderModalRef?.dismiss();
    this.formHasEdited = true;

    if (newWorkOrder.hasOwnProperty('predictedWorkOrderSplitInfo')) {
      this.predictedWorkOrderForSplit = newWorkOrder?.predictedWorkOrderSplitInfo;
    } else {
      this.predictedWorkOrderForSplit = null;
    }

    if (modalToClose !== null) {
      modalToClose.dismiss();
    }
    if (
      this.activityForm.activityName.value &&
      !(this.selectedActivityType === null || (this.isBulkEditForm && !this.activityForm.taskName.isEnabled))
    ) {
      this.setTaskPickerValue(this.activityForm.taskName.value, this.activityForm.equipmentName.value);
    }
  }

  public lineSelected(): void {
    this.clearSelectedActivityAndTask();

    if (this.calledFrom !== EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.clearWorkOrder();
    }

    if (this.activityForm.line.value && this.activityForm.line.value.length === 0) {
      this.activityForm.startedByUser.value = '';
      this.activityForm.endedByUser.value = '';
      this.users = [];
      return;
    }

    const siteName: string = this.sites.find(
      (site: DropdownOptionInterface) => site.id === this.activityForm.site.value[0].id,
    ).name;
    const lineId = Number(this.activityForm.line.value[0].id);
    this.selectWorkOrderModalConfigurations = {
      siteName,
      lineId,
      siteId: Number(this.activityForm.site.value[0].id),
      lineType: this.allLines.find((line: LineCRUDInterface) => line.id === lineId)?.lineType,
    };
  }

  public onUserSearch(value: string): void {
    this.store.dispatch(new HomeActions.SearchUsersByFilter(value, Number(this.activityForm.line.value[0].id)));
  }

  public onUserOpened(): void {
    this.userPlaceholder = null;
  }

  public openOverlappingActivityModal(): void {
    this.overlappingActivitiesModalRef = this.ngbModal.open(this.overlappingActivitiesModalTemplate, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-md',
    });
  }

  public closeOverlappingActivityModal(): void {
    this.overlappingActivitiesModalRef.close();
    this.store.dispatch(new ActivityHistoryActions.SetOverlapActivityParametersDefault());
  }

  public validateActivityOverlap(): void {
    const [startDate, endDate] = this.getStartEndDate();
    const overlappingData: OverlappingDataInterface = {
      siteId: this.activityForm.site.value[0].id,
      lineId: this.activityForm.line.value[0].id,
      start: startDate,
      end: endDate,
      activityId: parseInt(String(this.activityForm.activityType.value), this.radix),
      workOrderId: this.activityForm.workOrderTableId.value,
      taskId: this.activityForm.task ? parseInt(this.activityForm.task.value, this.radix) : null,
      crewSize: this.decimalHelper.sanitizeString(this.activityForm.crewSize.value) ?? null,
      phaseId:
        this.activityForm.phaseId && this.activityForm.phaseId.value !== '0'
          ? Number(this.activityForm.phaseId.value)
          : null,
      comments: this.inMemoryComments,
    };

    if (this.activityForm.id.value) {
      overlappingData.id = this.activityForm.id.value;
    }

    const mainActivity: FixedAndCurrentVersionInterface = {
      siteId: Number(this.activityForm.site.value[0].id),
      lineId: Number(this.activityForm.line.value[0].id),
      start: startDate,
      end: endDate,
      ...(this.activityForm.task
        ? {
            task: {
              id: Number(this.activityForm.task.value),
              title: this.activityForm.taskName.value,
              titleTranslations: this.activityForm.taskNameTranslations?.value,
            },
          }
        : {}),
      ...(this.activityForm.workOrderTableId
        ? {
            workOrder: {
              id: this.activityForm.workOrderTableId.value,
              woNumber: this.activityForm.workOrder.value,
            },
          }
        : {}),
      activity: {
        name: this.activityForm.activityName.value,
        nameTranslations: this.activityForm.activityNameTranslations.value,
        activityType: this.selectedActivityType,
      },
    };

    if (this.predictedWorkOrderForSplit) {
      overlappingData.predictedWorkOrderForSplit = this.predictedWorkOrderForSplit;
      mainActivity.predictedWorkOrderForSplit = this.predictedWorkOrderForSplit;
    }

    this.store.dispatch(new ActivityHistoryActions.ValidateActivityOverlap(overlappingData, mainActivity));
  }

  private updateOverlappingActivities(activityHistoriesToUpdate: SelectedActivityEventDataInterface[]): void {
    if (activityHistoriesToUpdate.length) {
      const activityDataToBeUpdated = [];
      for (const activity of activityHistoriesToUpdate) {
        activityDataToBeUpdated.push({
          id: activity.id,
          start: activity.start,
          end: activity.end,
          activityId: activity.activityId,
          taskId: activity.taskId,
          workOrderId: activity.workOrderId,
        });
      }
      this.store.dispatch(
        new ActivityHistoryActions.SaveOrUpdateBulkActivityEventData({
          activityHistories: activityDataToBeUpdated,
        }),
      );
    }
  }

  public setActivityDataValues(activityData: ActivityEditModalDataInterface): void {
    if (!activityData) {
      return;
    }

    this.activityForm.activityName.value = activityData.activity ? activityData.activity.name : null;
    this.activityForm.activityNameTranslations.value = activityData.activity
      ? activityData.activity.nameTranslations
      : null;
    this.activityForm.activityType.value = activityData.activity ? activityData.activity.id : null;
    this.selectedActivityType = activityData.activity ? activityData.activity.activityType : null;
    this.activityForm.taskName.value = activityData.task ? activityData.task.title : null;
    this.activityForm.taskNameTranslations.value = activityData.task ? activityData.task.titleTranslations : null;
    this.activityForm.task.value = activityData.task ? String(activityData.task.id) : null;
    this.activityForm.equipmentName.value = _.get(activityData, 'task.equipment.equipmentName', null);
    this.activityForm.productId.value =
      activityData.workOrder && activityData.workOrder.product ? activityData.workOrder.product.productId : null;
    this.activityForm.productDescription.value =
      activityData.workOrder && activityData.workOrder.product ? activityData.workOrder.product.description : null;
    this.activityForm.workOrderSize.value = activityData.workOrder ? activityData.workOrder.quantityOrdered : null;
    this.activityForm.workOrderTableId.value = activityData.workOrder ? activityData.workOrder.id : null;
    this.activityForm.workOrder.value = activityData.workOrder ? activityData.workOrder.woNumber : null;
    this.activityForm.phaseId.value = activityData.phaseId ? String(activityData.phaseId) : PhaseOptions.NO_PHASE;
    this.setTaskTreeRootAddress(this.activityForm.activityType.value);
  }

  public setTaskPickerValue(task: string, equipment: string): void {
    let taskEquipmentString: string = this.translate.instant('general.missingDataText');

    if (this.isBulkEditForm) {
      this.activityForm.taskName.isEnabled = true;

      if (this.defaultData.bulkEdit.taskMissingData) {
        this.isTaskMissingDataBulk = true;
      }
    }

    if (task && task !== '' && this.selectedActivityType !== ActivityTypes.RUN_TIME) {
      taskEquipmentString = task;

      if (equipment && equipment !== '') {
        taskEquipmentString = `${task} - ${equipment}`;
      }
    } else if (
      this.selectedActivityType === ActivityTypes.RUN_TIME &&
      this.activityForm.workOrder.value &&
      !this.isBulkEditForm
    ) {
      taskEquipmentString = this.setTaskIfActivityIsRun();
    }

    if (this.activityForm.workOrder.value || taskEquipmentString) {
      this.taskPickerInputModel = {
        value: taskEquipmentString ? taskEquipmentString : this.translate.instant('general.missingDataText'),
        type:
          (this.selectedActivityType === ActivityTypes.RUN_TIME && this.activityForm.productDescription.value) ||
          (taskEquipmentString && task && task !== '')
            ? ScwMatPickerTypeEnum.DEFAULT
            : ScwMatPickerTypeEnum.MISSING_DATA,
        text: taskEquipmentString ? taskEquipmentString : this.translate.instant('general.missingDataText'),
      };
    }
  }

  public setActivityPickerValue(activity: string, formInstance: ScwMatPickerComponent = null): void {
    if (this.selectedActivityType === ActivityTypes.RUN_TIME) {
      this.selectedActivityColor = 'run-time';
    } else if (this.selectedActivityType === ActivityTypes.DOWN_TIME) {
      this.selectedActivityColor = 'unplanned-down-time';
    } else if (this.selectedActivityType === ActivityTypes.DOWN_TIME_PLANNED) {
      this.selectedActivityColor = 'planned-down-time';
    } else if (this.selectedActivityType === ActivityTypes.IDLE_TIME) {
      this.selectedActivityColor = 'default';
      formInstance?.clearErrorMessage();
    }

    this.activityPickerInputModel = {
      value: activity,
      type: this.selectedActivityColor ? this.selectedActivityColor : ScwMatPickerTypeEnum.MISSING_DATA,
      text: activity,
    };
  }

  public filterLines(): void {
    this.lines = this.allLines
      .filter((line: LineCRUDInterface) => line.siteId === this.activityForm.site.value[0].id)
      .map((line: LineCRUDInterface) => {
        return {
          id: line.id,
          name: line.title,
          lineType: line.lineType,
        };
      });
  }

  public onBulkEditCheckboxChange(
    value: boolean,
    index: string,
    formComponentInstance: (
      | ScwMatInputComponent
      | ScwMatSelectComponent
      | ScwMatTextareaComponent
      | ScwMatPickerComponent
    )[],
  ): void {
    if (value && !(index === 'workOrder' && this.selectedActivityType === ActivityTypes.IDLE_TIME)) {
      _.set(this.activityForm, `${index}.rules`, _.get(this.activityFormRules, index, []));
    } else {
      _.set(this.activityForm, `${index}.rules`, []);

      if (index === 'activityName') {
        this.isBulkRunTime = false;
        this.activityForm.taskName.rules = [];
        this.activityForm.taskName.value = null;
        this.activityForm.taskName.isEnabled = false;
        this.taskPickerInputModel = { value: null, type: ScwMatPickerTypeEnum.DEFAULT, text: null };
        this.activityPickerInputModel = { value: null, type: ScwMatPickerTypeEnum.DEFAULT, text: null };
        this.selectedActivityType = null;
      }

      for (const instance of formComponentInstance) {
        instance.reset();
      }
    }

    this.areAnyOfTheBulkEditFormSelectBoxesSelected = _.findKey(this.activityForm, (item) => {
      return item.isEnabled;
    });
  }

  public ngOnDestroy(): void {
    clearInterval(this.updateInterval);
    if (this.searchTaskByActivityIdActionSubscription) {
      this.searchTaskByActivityIdActionSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.activityHistoryStoreSubscription) {
      this.activityHistoryStoreSubscription.unsubscribe();
    }
    if (this.saveUpdateOrDeleteActivityEventDataCompletedSubscription) {
      this.saveUpdateOrDeleteActivityEventDataCompletedSubscription.unsubscribe();
    }
    if (this.lineSubscription) {
      this.lineSubscription.unsubscribe();
    }
    if (this.homeActionSubscription) {
      this.homeActionSubscription.unsubscribe();
    }
    if (this.homePageDisplaySettingsSubscription) {
      this.homePageDisplaySettingsSubscription.unsubscribe();
    }

    this.storeSubscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }

  public cancelEmitter(): void {
    this.cancel.emit(true);
  }

  public checkPhaseDropdown(): void {
    this.phaseSelectComponent.selectComponent.onNgModelChange();
    this.isPhaseValid = this.phaseSelectComponent.selectComponent.isValid;
  }

  public setWorkOrderPickerValue(workOrder: string, productId: string, productDescription: string): void {
    this.runWithoutWorkOrder = Boolean(this.defaultData?.task?.meta?.withoutWorkOrder);

    if (this.selectedActivityType === ActivityTypes.IDLE_TIME) {
      this.activityForm.workOrder.rules = [];
    } else {
      _.set(this.activityForm, 'workOrder.rules', _.get(this.activityFormRules, 'workOrder', []));
    }

    if (
      this.activityForm.workOrder.value &&
      this.activityForm.productId.value &&
      this.activityForm.productDescription.value
    ) {
      this.workOrderProductText = `${workOrder} -
            ${productId} ${productDescription}`;
    }

    this.workOrderPickerInputModel = {
      text:
        this.workOrderProductText || this.selectedActivityType === 'idleTime' || this.runWithoutWorkOrder
          ? this.workOrderProductText
          : this.translate.instant('general.missingDataText'),
      type:
        this.workOrderProductText || this.selectedActivityType === 'idleTime' || this.runWithoutWorkOrder
          ? ScwMatPickerTypeEnum.DEFAULT
          : ScwMatPickerTypeEnum.MISSING_DATA,
      value:
        workOrder || this.selectedActivityType === 'idleTime' || this.runWithoutWorkOrder
          ? workOrder
          : this.translate.instant('general.missingDataText'),
    };
  }

  public deleteActivityHistoryModal(content: TemplateRef<any>): void {
    this.activityHistoryDeleteModalRef = this.ngbModal.open(content, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }

  public resetForm(): void {
    this.activityForm = {
      id: { isEnabled: true, value: null },
      startDate: { isEnabled: true, value: { startDate: null, endDate: null } },
      endDate: { isEnabled: true, value: { startDate: null, endDate: null } },
      startedByUser: { isEnabled: true, value: null },
      endedByUser: { isEnabled: true, value: null },
      site: { isEnabled: true, value: null },
      line: { isEnabled: true, value: null },
      activityType: { isEnabled: true, value: null },
      activityName: { isEnabled: true, value: null },
      activityNameTranslations: { isEnabled: true, value: null },
      task: { isEnabled: true, value: null },
      taskName: { isEnabled: true, value: null },
      taskNameTranslations: { isEnabled: true, value: null },
      workOrder: { isEnabled: true, value: null },
      workOrderSize: { isEnabled: true, value: null },
      workOrderTableId: { isEnabled: true, value: null },
      crewSize: { isEnabled: true, value: null },
      productDescription: { isEnabled: true, value: null },
      productId: { isEnabled: true, value: null },
      phaseId: { isEnabled: true, value: null },
      equipmentName: { isEnabled: true, value: null },
    };
    this.activityPickerInputModel = null;
    this.taskPickerInputModel = null;
    this.workOrderPickerInputModel = null;
  }

  private setTaskTreeRootAddress(activityId: number): void {
    this.taskTreeRootAddress = {
      activityId,
      lineId: _.get(this.activityForm.line.value, '0.id', this.lineId),
    };
  }

  private updateDatepickerContext(
    context: IDynamicComponentDirectiveContext,
    isStart: boolean,
  ): IDynamicComponentDirectiveContext {
    const customElement: any = HelperService.cloneDeep(context.customElement);
    customElement.inputValues['weekStartDay'] = this.weekStartDay;
    customElement.inputValues['inputModel'] = isStart
      ? this.activityForm.startDate.value
      : this.activityForm.endDate.value;

    if (isStart) {
      customElement.inputValues['maxDate'] = this.activityForm.endDate.value?.startDate ?? this.maxDate;
    } else {
      customElement.inputValues['minDate'] = this.activityForm.startDate.value?.startDate;
      customElement.inputValues['maxDate'] = this.maxDate;
    }

    context = {
      ...context,
      customElement,
    };
    return context;
  }

  public startDatepickerEventListener(event): void {
    this.startDatePickerInstance = event;
    this.startDatePickerSub?.unsubscribe();
    this.startDatePickerSub = event?.inputModelChange.subscribe((val) => {
      this.activityForm.startDate.value = val;
      this.endDatePickerInstance.minDate = this.activityForm.startDate.value?.endDate;
      this.endDatePickerInstance.maxDate = this.maxDate;
    });
  }

  public endDatepickerEventListener(event): void {
    this.endDatePickerInstance = event;
    this.endDatePickerSub?.unsubscribe();
    this.endDatePickerSub = event?.inputModelChange.subscribe((val) => {
      this.activityForm.endDate.value = val;
      this.startDatePickerInstance.maxDate = this.activityForm.endDate.value?.startDate ?? this.maxDate;
    });
  }

  private areDatePickersValid(): boolean {
    if (this.startDatePickerInstance) {
      this.startDatePickerInstance.hasErrors = _.isNil(this.activityForm?.startDate?.value);
      this.startDatePickerInstance.errorText = this.startDatePickerInstance.hasErrors
        ? this.translate.instant('scwMatForm.validation.required')
        : '';

      this.endDatePickerInstance.hasErrors = _.isNil(this.activityForm?.endDate?.value);
      this.endDatePickerInstance.errorText = this.endDatePickerInstance.hasErrors
        ? this.translate.instant('scwMatForm.validation.required')
        : '';
    }

    return !this.startDatePickerInstance?.hasErrors && !this.endDatePickerInstance?.hasErrors;
  }
}
