import { Injectable } from '@angular/core';
import {
  IFormattedAndGroupedGanttItems,
  IScenarioUncreatedWorkOrder,
  ScenarioInterface,
  ScenarioItemInterface,
  ScenarioItemSaveInterface,
  IOptimizationChildItem,
  ISchedulerOptimizationItem,
  ISchedulerOptimizationResponse,
} from '../../../store/scheduler/scheduler.model';
import * as _ from 'lodash';
import { ActivitiesInterface } from '../../model/interface/activities.model';
import {
  FormattedWorkOrdersResponseInterface,
  WorkOrderInterface,
  WorkOrderResponseInterface,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import { PresetLinesInterface } from '../../../store/scheduler-resource-setting/scheduler-resource-setting.model';
import {
  EventTooltipDataInterface,
  GanttScenarioItem,
  TaskType,
} from '../../../view/scheduler/gantt-view/gantt-view.model';
import { DateHelper } from '@bryntum/schedulerpro';
import { TranslateService } from '@ngx-translate/core';
import { ToFixedPipe } from '../../pipe/decimal/decimal-to-fixed.pipe';
import * as moment from 'moment/moment';
import { mysqlTimestampFormat } from '../../helper/date';
import { HelperService } from '../helper.service';
import { PhaseOptions } from '../../model/enum/phase-options';

@Injectable({
  providedIn: 'root',
})
export class SchedulerHelperService {
  private readonly tooltipHeaderStyle: string = 'font-size: 16px; margin-bottom: 10px';
  private readonly tooltipSeperatorStyle: string =
    'width: 100%; border-bottom: 1px solid #616161; margin-top: 12px; margin-bottom: 12px';
  public readonly tooltipDataStyle: string = 'font-size: 12px';
  private readonly tooltipDisplayNoneStyle: string = 'display: none;';

  constructor(public translate: TranslateService) {}

  public formatResponse(workOrderResponse: WorkOrderResponseInterface): FormattedWorkOrdersResponseInterface {
    const { rows, ...counts } = workOrderResponse.data;

    return {
      ...workOrderResponse,
      ...counts,
      data: rows.map((row: WorkOrderInterface) => {
        return {
          ...row,
          goodCount: row.lastOeeCalculation?.GoodQuantity,
          initialCount: row.lastOeeCalculation?.TotalInitialCount,
        };
      }),
    };
  }

  public schedulerTooltipTemplate(
    data: EventTooltipDataInterface,
    decimalScaleLimit: number,
    dateFormats: {
      dateFormat: string;
      dateTimeFormat: string;
    },
    locale: string,
    toFixedPipe: ToFixedPipe,
    isPhaseViewMode: boolean = false,
  ): string {
    if (data.eventRecord.type === TaskType.unplannedWorkOrder) {
      const isUncreatedJob: boolean = data.eventRecord?.workOrderSchedule?.job?.isUncreatedJob;
      let tooltipHtml: string = `
          <div style='${this.tooltipHeaderStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.workOrder')}</b>
          </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.workOrderNumber')}:</b>
          ${data.eventRecord.workOrderSchedule?.woNumber} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.productId')}:</b>
          ${data.eventRecord.workOrderSchedule?.product?.productId ?? '-'} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.description')}:</b>
          ${data.eventRecord.workOrderSchedule?.product?.description ?? '-'} </div>
          <div style='${isUncreatedJob ? this.tooltipDataStyle : this.tooltipDisplayNoneStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.currentJob')}:</b>
          ${data.eventRecord.workOrderSchedule?.job?.currentJobName} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant(
            isUncreatedJob ? 'scheduler.ganttView.newJob' : 'scheduler.ganttView.jobNumber',
          )}:</b>
          ${data.eventRecord.workOrderSchedule?.job?.jobName ?? '-'} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.sequenceNumber')}:</b>
          ${data.eventRecord.workOrderSchedule?.sequenceNumber ?? '-'} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.quantity')}:</b>
          ${toFixedPipe.transform(data.eventRecord.workOrderSchedule?.quantityOrdered, decimalScaleLimit)}</div>
          <div style='${this.tooltipDataStyle}'>
            <b>${this.translate.instant('scheduler.ganttView.releaseDate')}:</b>
            ${
              data.eventRecord.workOrderSchedule?.releaseDate
                ? moment(data.eventRecord.workOrderSchedule?.releaseDate).locale(locale).format(dateFormats.dateFormat)
                : '-'
            } </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.dueDate')}:</b>
          ${
            data.eventRecord.workOrderSchedule.woDueDate
              ? moment(data.eventRecord.workOrderSchedule?.woDueDate).locale(locale).format(dateFormats.dateTimeFormat)
              : '-'
          }
          </div>`;

      tooltipHtml = this.schedulerTooltipAddScheduleValues(
        tooltipHtml,
        data,
        decimalScaleLimit,
        dateFormats,
        locale,
        toFixedPipe,
        isPhaseViewMode,
      );

      if (data.eventRecord.id.toString().includes('_actual') && !data.eventRecord.parentId) {
        tooltipHtml = tooltipHtml.concat(
          `<div>
          <b>${this.translate.instant('scheduler.ganttView.completionPercentage')}:</b>
          ${toFixedPipe.transform(data.eventRecord.percentDone, decimalScaleLimit)} %</div>`,
        );
      }

      return tooltipHtml;
    } else if (data.eventRecord.type === TaskType.downTimeActivity) {
      const isPreAndPostRunPhase: boolean = data.eventRecord?.phaseName !== '-';
      const phaseName: string = isPreAndPostRunPhase
        ? data.eventRecord?.phaseName
        : this.translate.instant('general.activityType.downTimePlanned');
      const tooltipHtml: string = `
          <div style='${this.tooltipHeaderStyle}'>
            <b>${this.translate.instant('scheduler.ganttView.activity')}</b>
          </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.activityName')}:</b>
          ${data.eventRecord.activity?.name} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant(
            isPreAndPostRunPhase ? 'scheduler.ganttView.activityPhase' : 'scheduler.ganttView.activityType',
          )}:</b>
          ${phaseName} </div>`;

      return this.schedulerTooltipAddScheduleValues(
        tooltipHtml,
        data,
        decimalScaleLimit,
        dateFormats,
        locale,
        toFixedPipe,
        isPhaseViewMode,
      );
    }

    return '';
  }

  private schedulerTooltipAddScheduleValues(
    tooltipHtml: string,
    data: EventTooltipDataInterface,
    decimalScaleLimit: number,
    dateFormats: {
      dateFormat: string;
      dateTimeFormat: string;
    },
    locale: string,
    toFixedPipe: ToFixedPipe,
    isPhaseViewMode: boolean = false,
  ): string {
    return tooltipHtml.concat(
      `<div style='${this.tooltipSeperatorStyle}'></div>
          <div style='${this.tooltipHeaderStyle}'>
            <b>${
              data.eventRecord.type === TaskType.unplannedWorkOrder
                ? this.translate.instant(
                    isPhaseViewMode
                      ? data.eventRecord.children?.length
                        ? 'scheduler.ganttView.workOrderSchedule'
                        : 'scheduler.ganttView.runTimeSchedule'
                      : 'scheduler.ganttView.workOrderSchedule',
                  )
                : this.translate.instant('scheduler.ganttView.activitySchedule')
            }</b>
          </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.scheduledStart')}:</b>
            ${moment(data.eventRecord.startDate).locale(locale).format(dateFormats.dateTimeFormat)} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.scheduledEnd')}:</b>
            ${moment(data.eventRecord.endDate).locale(locale).format(dateFormats.dateTimeFormat)} </div>
          <div style='${this.tooltipDataStyle}'>
          <b>${this.translate.instant('scheduler.ganttView.duration')}:</b>
          ${toFixedPipe.transform(data.eventRecord.duration, decimalScaleLimit)} ${this.translate.instant(
        'general.shortHour',
      )}</div>`,
    );
  }

  public formattedSchedulerOptimization(
    optimizationData: ISchedulerOptimizationResponse,
    workOrders: WorkOrderInterface[],
    lines: PresetLinesInterface[],
    activities: ActivitiesInterface[],
    selectedScenario: ScenarioInterface,
  ): ScenarioItemInterface[] {
    const childWorkOrders: ScenarioItemInterface[] = [];
    const optimizations: ScenarioItemInterface[] = optimizationData.optimizations
      .map((data: ISchedulerOptimizationItem) => {
        const workOrder: WorkOrderInterface | undefined = workOrders?.find(
          (wo: WorkOrderInterface) => wo.id === data.workOrderId,
        );
        const line: PresetLinesInterface | undefined = lines?.find(
          (line: PresetLinesInterface) => line.lineId === data.lineId,
        );
        const activity: ActivitiesInterface | undefined = activities?.find(
          (activity: ActivitiesInterface) => activity.id === data.activityId,
        );
        const isValid: boolean = !!line && (!!workOrder || !!activity);

        if (isValid && workOrder && data.childWorkOrders?.length) {
          childWorkOrders.push(
            ...data.childWorkOrders.map((childWorkOrder: IOptimizationChildItem) => {
              const childWorkOrderLine: PresetLinesInterface | undefined = lines?.find(
                (line: PresetLinesInterface) => line.lineId === childWorkOrder.lineId,
              );

              return {
                id: null,
                isUncreatedWorkOrder: true,
                lineId: childWorkOrder.lineId,
                title: childWorkOrderLine.line.title,
                name: childWorkOrder.workOrderName,
                workOrderScheduleId: data?.workOrderId,
                startDate: childWorkOrder.startDate,
                endDate: childWorkOrder.endDate,
                scenarioId: selectedScenario.id,
                workOrderSchedule: {
                  actualDuration: workOrder.actualDuration,
                  actualRunDate: workOrder.actualRunDate,
                  averageDuration: workOrder.averageDuration,
                  id: workOrder.id,
                  lineType: workOrder.lineType,
                  product: workOrder.product,
                  productId: workOrder.productId,
                  quantityOrdered: workOrder.quantityOrdered,
                  releaseDate: workOrder.releaseDate,
                  scheduledEndDate: workOrder.scheduledEndDate,
                  scheduledLine: workOrder.scheduledLine,
                  scheduledRunDate: workOrder.scheduledRunDate,
                  site: workOrder.site,
                  siteId: workOrder.siteId,
                  woDueDate: workOrder.woDueDate,
                  woNumber: childWorkOrder.workOrderName,
                  sequenceNumber: childWorkOrder.sequenceNumber,
                  scheduledLinePathId: data.scheduledLinePathId,
                  job: {
                    id: data.jobId,
                    jobName: data.jobName,
                  },
                },
                line: {
                  id: line.id,
                  title: line.line.title,
                },
                type: TaskType.unplannedWorkOrder,
                activityId: null,
                activity: null,
              };
            }),
          );
        }

        return isValid
          ? {
              id: null,
              isUncreatedWorkOrder: false,
              lineId: data.lineId,
              title: line.line.title,
              name: data.workOrderId ? workOrder.woNumber : activity.name,
              workOrderScheduleId: data?.workOrderId,
              startDate: data.startDate,
              endDate: data.endDate,
              scenarioId: selectedScenario.id,
              workOrderSchedule: data?.workOrderId
                ? {
                    actualDuration: workOrder.actualDuration,
                    actualRunDate: workOrder.actualRunDate,
                    averageDuration: workOrder.averageDuration,
                    id: workOrder.id,
                    jobNumber: workOrder.jobNumber,
                    lineType: workOrder.lineType,
                    product: workOrder.product,
                    productId: workOrder.productId,
                    quantityOrdered: workOrder.quantityOrdered,
                    releaseDate: workOrder.releaseDate,
                    scheduledEndDate: workOrder.scheduledEndDate,
                    scheduledLine: workOrder.scheduledLine,
                    scheduledRunDate: workOrder.scheduledRunDate,
                    site: workOrder.site,
                    siteId: workOrder.siteId,
                    woDueDate: workOrder.woDueDate,
                    woNumber: workOrder.woNumber,
                    jobId: workOrder.jobId,
                  }
                : null,
              line: {
                id: line.id,
                title: line.line.title,
              },
              type: data.workOrderId ? TaskType.unplannedWorkOrder : TaskType.downTimeActivity,
              activityId: data?.activityId,
              phaseName: this.getPhaseName(selectedScenario, activity?.activityPlannedType),
              activity: data?.activityId
                ? {
                    id: data.activityId,
                    name: activity.name,
                  }
                : null,
            }
          : null;
      })
      .filter((item: ScenarioItemInterface) => item !== null);

    return [...optimizations, ...childWorkOrders];
  }

  public createScenarioItem(
    item: GanttScenarioItem,
    scenarioId: number,
    isDeploy: boolean = false,
  ): ScenarioItemSaveInterface {
    const scenarioItem: ScenarioItemSaveInterface = {
      childItems: [],
      startDate: moment(item.startDate).format(mysqlTimestampFormat),
      endDate: moment(item.endDate).format(mysqlTimestampFormat),
    };

    if (!isDeploy) {
      _.set(scenarioItem, 'scenarioId', scenarioId);
      _.set(scenarioItem, 'lineId', Number(item.resourceId));
    }

    if (item.type === TaskType.unplannedWorkOrder) {
      _.set(scenarioItem, 'workOrderScheduleId', item?.workOrderSchedule?.id);
      _.set(scenarioItem, 'scheduledLinePathId', item?.workOrderSchedule?.scheduledLinePathId);
    }

    if (item.type === TaskType.downTimeActivity) {
      _.set(scenarioItem, 'activityId', Number(item?.activity?.id.toString().split('_')[0]));

      if (isDeploy) {
        _.set(scenarioItem, 'activityPlannedType', item?.activity?.activityPlannedType);
      }
    }

    return HelperService.cloneDeep(scenarioItem);
  }

  public formatWithMomentDuration(
    duration: number | string | null,
    durationType: moment.DurationInputArg2 = 'seconds',
    suffix: boolean = false,
  ): string {
    if (duration === null || duration === '') {
      return null;
    }

    const baseDuration: moment.Duration = moment.duration(
      _.isNaN(Number(duration)) ? String(duration) : Number(duration),
      durationType,
    );

    if (!baseDuration.isValid()) {
      return String(duration);
    }

    let daySeparator: string = ':';
    let hourSeparator: string = ':';
    let minuteSeparator: string = '';
    let secondSeparator: string = '';

    if (suffix) {
      daySeparator = `${this.translate.instant('general.shortDay')} `;
      hourSeparator = `${this.translate.instant('general.shortHour')} `;
      minuteSeparator = `${this.translate.instant('general.shortMinute')} `;
      secondSeparator = `${this.translate.instant('general.shortSecond')} `;
    }

    const subDuration: moment.Duration = baseDuration.clone();
    const day: number = subDuration
      .subtract(baseDuration.hours(), 'hours')
      .subtract(baseDuration.minutes(), 'minutes')
      .subtract(baseDuration.seconds(), 'seconds')
      .subtract(baseDuration.milliseconds(), 'milliseconds')
      .asDays();
    const hour: string = String(baseDuration.get('hours')).padStart(2, '0');

    return `${day !== 0 ? day + daySeparator : ''}${day !== 0 || hour !== '00' ? hour + hourSeparator : ''}
      ${String(baseDuration.get('minutes')).padStart(2, '0')}${minuteSeparator}
      ${String(baseDuration.get('seconds')).padStart(2, '0')}${secondSeparator}`;
  }

  public getScenarioItemsAndUncreatedWorkOrders(
    ganttAllItems: GanttScenarioItem[],
    selectedScenario: ScenarioInterface,
    isDeploy: boolean = false,
  ): IFormattedAndGroupedGanttItems {
    const ganttItems = ganttAllItems.reduce(
      (
        groupedGanttItems: {
          uncreatedWorkOrders: GanttScenarioItem[];
          planItems: GanttScenarioItem[];
        },
        ganttItem: GanttScenarioItem,
      ) => {
        if (ganttItem.isUncreatedWorkOrder) {
          groupedGanttItems.uncreatedWorkOrders.push(ganttItem);
        } else if (!ganttItem.isUncreatedWorkOrder) {
          groupedGanttItems.planItems.push(ganttItem);
        }

        return groupedGanttItems;
      },
      { uncreatedWorkOrders: [], planItems: [] },
    );

    let uncreatedWorkOrders: IScenarioUncreatedWorkOrder[] = [];

    if (!selectedScenario.isPhaseViewMode) {
      uncreatedWorkOrders = ganttItems.uncreatedWorkOrders.map((item: GanttScenarioItem) => {
        return {
          scenarioId: selectedScenario.id,
          parentWorkOrderId: item.workOrderScheduleId,
          siteId: selectedScenario.siteId,
          lineId: Number(item.lineId),
          startDate: moment(item.startDate).format(mysqlTimestampFormat),
          endDate: moment(item.endDate).format(mysqlTimestampFormat),
          jobId: item.workOrderSchedule?.job?.id,
          jobName: item.workOrderSchedule?.job?.jobName,
          workOrderIndex: Number(item.name.split('*')[1]),
          scheduledLinePathId: item.workOrderSchedule.scheduledLinePathId,
          sequenceNumber: item.workOrderSchedule.sequenceNumber,
          ...(isDeploy && { id: Number(item.id.toString().split('_uncreated')[0]) }),
        };
      });
    }

    return {
      scenarioItems: ganttItems.planItems,
      uncreatedWorkOrders,
    };
  }

  public getPhaseName(scenario: ScenarioInterface, phaseId: number): string {
    if (phaseId === Number(PhaseOptions.PRE_RUN)) {
      return scenario?.site?.preRunPhaseName ?? this.translate.instant('scheduler.resourceSetting.preActivity');
    }

    if (phaseId === Number(PhaseOptions.POST_RUN)) {
      return scenario?.site?.postRunPhaseName ?? this.translate.instant('scheduler.resourceSetting.postActivity');
    }

    return '-';
  }

  public uncreatedWorkOrderEventRenderer = ({ eventRecord }: { eventRecord: GanttScenarioItem }): string => {
    const dNoneClass: string = _.get(eventRecord, '_data.isUncreatedWorkOrder', false) ? '' : 'd-none';
    return `
        <div class="info width-100">
            <div class="row">
            <div class="col-12">
            <img class="uncreated_work_order ${dNoneClass}" src="assets/icon/scw_icons/uncreated_work_order_icon.svg" alt="Uncreated Work Order" />
            ${eventRecord['data'].name}
          </div>
        </div>
      </div>
    `;
  };
}
