import {
  ContainerItemConfig,
  DragHelper,
  EditorTab,
  EventModel,
  EventStore,
  Grid,
  GridColumnConfig,
  Model,
  ResourceModel,
  ResourceStore,
  ResourceTimeRangeModel,
  ResourceTimeRangeStore,
  Store,
} from '@bryntum/schedulerpro';
import * as _ from 'lodash';
import { ScenarioItemInterface, IWorkOrderScheduleForScheduler } from '../../../store/scheduler/scheduler.model';
import { DurationUnit } from '../../line-availability/line-availability.model';
import {
  IGridTitleTranslationSettings,
  ISchedulerWorkOrder,
  UnplannedActivitiesInterface,
} from './gantt-view.component.model';
import { PresetLinesInterface } from '../../../store/scheduler-resource-setting/scheduler-resource-setting.model';
import { IEntityTranslation } from '../../../shared/service/entity-translator/entity-translator.model';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import { HelperService } from '../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerInterface } from '../../settings/customer-settings/customers/customers.model';

export enum ColorDropdownProps {
  DUE_DATES = 'dueDates',
  WORK_ORDER = 'workOrder',
  WARNING_STATUS = 'warningStatus',
  DEFAULT = 'default',
  READINESS = 'readiness',
  JOB = 'job',
  PRODUCT = 'product',
  PRODUCT_FAMILY = 'productFamily',
  CUSTOMER = 'customer',
  CUSTOMER_SEGMENT = 'customerSegment',
}

export enum DisplayModeDropdownProps {
  ACTUAL = 'actual',
  DEPLOYED = 'deployed',
  DEFAULT = 'default',
  PLANNED = 'planned',
}

export enum SchedulerViewEnum {
  GANTT_VIEW = 'gant-view',
  LABOR_SCHEDULER = 'labor-scheduler',
  DASHBOARD_VIEW = 'dashboard-view',
}

export interface EventDragValidatorInterface<T> {
  eventRecords: T[];
  context: any;
}

export interface SchedulerHomeGanttEventRecord extends EventModel {
  data: any;
}

export interface ContextValidControlInterface {
  isLineValid: boolean;
  isSiteValid: boolean;
  valid: boolean;
  isResourceAndDateValid: boolean;
  isScenarioSelected?: boolean;
  isHorizonValid?: boolean;
  isTargetNestedEvent?: boolean;
  task: any;
}

export interface EventTooltipDataInterface {
  eventRecord: GanttScenarioItem;
  tip: {
    tools: {
      [key: number]: {
        hidden: boolean;
      };
    };
  };
}

export interface ColorAssignmentsForIds {
  [id: number]: string;
}

export type ColorAssignmentInterface = {
  [key in TaskType]?: ColorAssignmentsForIds;
};

export enum TaskType {
  unplannedWorkOrder = 'unplannedWo',
  downTimeActivity = 'downTimeActivity',
  user = 'user',
  line = 'line',
  team = 'team',
}

export class BaseEventModel extends EventModel {
  public type?: TaskType;
  public workOrderSchedule?: IWorkOrderScheduleForScheduler;
  public activity?: UnplannedActivitiesInterface;
  public line?: PresetLinesInterface;

  static override get defaults() {
    return {
      durationUnit: DurationUnit.HOUR,
      draggable: true,
      isDraggable: true,
      constraintDate: false,
      constraintType: false,
    };
  }
}

export class GanttScenarioItem extends BaseEventModel {
  activityId: number;
  workOrderScheduleId: number;
  warning?: boolean;
  siteId?: number;
  scenarioId?: number;
  lineId?: number;
  scheduledPreDuration?: number | null;
  scheduledPostDuration?: number | null;
  baseId?: number;
  entityTranslations?: IEntityTranslation[];
  parentType?: TaskType;
  totalDuration?: number;
  phaseName?: string;
  override children: Partial<GanttScenarioItem>[];
  isUncreatedWorkOrder?: boolean;
  workOrderIndex?: number;

  constructor(data: object) {
    let name: string | null = _.get(data, 'workOrderSchedule.woNumber', null);

    if (name === null) {
      _.set(data, 'type', TaskType.downTimeActivity);
      _.set(data, 'activityId', _.get(data, 'activity.activityId', null));
    } else {
      _.set(data, 'type', TaskType.unplannedWorkOrder);
      _.set(data, 'siteId', _.get(data, 'workOrderSchedule.siteId', null));
      _.set(data, 'workOrderScheduleId', _.get(data, 'workOrderSchedule.id', null));
    }

    super(data);
  }

  static override get fields() {
    return [
      { name: 'resourceId', dataSource: 'lineId' },
      { name: 'siteId', dataSource: 'siteId' },
      { name: 'type' },
      { name: 'workOrderSchedule' },
      { name: 'activity' },
      { name: 'activityId' },
      { name: 'warning' },
      { name: 'workOrderScheduleId' },
      { name: 'scenarioId' },
      { name: 'lineId' },
      { name: 'children', dataSource: 'children' },
      { name: 'phaseName', dataSource: 'phaseName' },
    ];
  }

  static get $$name() {
    return 'GanttScenarioItem';
  }
}

export class GanttScenarioItemStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: GanttScenarioItem,
    };
  }
}

export class UnplannedWoEvent extends BaseEventModel {
  static get $$name() {
    return 'UnplannedWoEvent';
  }

  constructor(data: ISchedulerWorkOrder) {
    _.set(data, 'type', TaskType.unplannedWorkOrder);
    _.set(data, 'name', data.woNumber);

    let duration: number = data.scheduledRunDuration
      ? Number(data.scheduledRunDuration)
      : Math.round((data.averageDuration / 60) * 100) / 100;

    _.set(data, 'duration', HelperService.cloneDeep(duration.toFixed(2)));

    if (data.isPhaseViewMode) {
      if (data.scheduledPreDuration) {
        duration += Number(data.scheduledPreDuration);
      }

      if (data.scheduledPostDuration) {
        duration += Number(data.scheduledPostDuration);
      }
    }

    _.set(data, 'totalDuration', HelperService.cloneDeep(duration.toFixed(2)));

    super(data);
  }

  static override get defaults() {
    return {
      reapplyFilterOnAdd: true,
      reapplyFilterOnUpdate: true,
      durationUnit: DurationUnit.HOUR,
      draggable: true,
      isDraggable: true,
      constraintDate: false,
      constraintType: false,
    };
  }

  static override get fields() {
    return [
      { name: 'name', dataSource: 'name' },
      { name: 'woNumber', dataSource: 'woNumber' },
      { name: 'siteId', dataSource: 'siteId' },
      { name: 'product', dataSource: 'product' },
      { name: 'productId', dataSource: 'productId' },
      { name: 'woDueDate', dataSource: 'woDueDate' },
      { name: 'type' },
      { name: 'totalDuration' },
    ];
  }
}

export class UnplannedWoEventStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: UnplannedWoEvent,
    };
  }
}

export class UnplannedActivityEvent extends BaseEventModel {
  constructor(data: UnplannedActivitiesInterface) {
    _.set(data, 'type', TaskType.downTimeActivity);

    if (!_.has(data, 'duration')) {
      _.set(data, 'duration', 8);
      _.set(data, 'totalDuration', 8);
    }

    super(data);
  }

  static override get fields() {
    return [
      { name: 'name', dataSource: 'name' },
      { name: 'activityType', dataSource: 'activityType' },
      { name: 'activitySubtype', dataSource: 'activitySubtype' },
      { name: 'activityPlannedType', dataSource: 'activityPlannedType' },
      { name: 'active', dataSource: 'active' },
      { name: 'activityId', dataSource: 'id' },
      { name: 'type' },
      { name: 'totalDuration' },
    ];
  }

  static get $$name() {
    return 'UnplannedActivityEvent';
  }
}

export class UnplannedActivityEventStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: UnplannedActivityEvent,
    };
  }
}

export class LineResourceEvent extends ResourceModel {
  constructor(data: GanttTreeResourceRowInterface) {
    super(data);
  }

  static get $$name() {
    return 'LineResourceEvent';
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'lineType', dataSource: 'lineType' },
      { name: 'lineTypeId', dataSource: 'lineTypeId' },
      { name: 'name', dataSource: 'name' },
      { name: 'activityIds', dataSource: 'activityIds' },
      { name: 'siteId', dataSource: 'siteId' },
      { name: 'schedulerShiftPlanId', dataSource: 'schedulerShiftPlanId' },
    ];
  }
}

export class LineResourceEventStore extends ResourceStore {
  static get defaultConfig() {
    return {
      modelClass: LineResourceEvent,
    };
  }
}

export class LineResourceTimeRange extends ResourceTimeRangeModel {
  static get $$name() {
    return 'LineResourceTimeRange';
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'resourceId', dataSource: 'resourceId' },
      { name: 'startDate', dataSource: 'startDate' },
      { name: 'endDate', dataSource: 'endDate' },
      { name: 'cls', dataSource: 'cls' },
    ];
  }
}

export class LineResourceTimeRangeStore extends ResourceTimeRangeStore {
  static get defaultConfig() {
    return {
      modelClass: LineResourceTimeRange,
    };
  }
}

export interface EventStoreUpdateInterface {
  source: EventStore;
  record: GanttScenarioItem;
  changes: any;
  type: string;
}

export interface GanttTreeResourceRowInterface {
  id: string;
  name: string;
  lineType: string;
  lineTypeId: number;
  siteId: number;
  schedulerShiftPlanId?: number;
  activityIds?: string;
  expanded: boolean;
  children?: GanttTreeResourceRowInterface[];
  cls?: string;
}

export interface GanttTreeResourceLineAsObjectInterface {
  id: string;
  name: string;
  expanded: boolean;
  lines?: {
    [lineId: string]: GanttTreeResourceRowInterface;
  };
  lineType: string;
  lineTypeId: number;
  siteId: number;
  activityIds?: string;
  cls?: string;
  laborCapacity?: number;
  readOnly?: boolean;
}

export interface GanttTreeResourceDepartmentAsObjectInterface {
  [departmentId: string]: GanttTreeResourceLineAsObjectInterface;
}

export interface GanttTreeEventInterface extends ScenarioItemInterface {
  resourceId?: string;
  draggable?: boolean;
  resizable?: boolean;
  type?: TaskType;
  manuallyScheduled?: boolean;
  siteId?: number;
  children?: GanttTreeEventInterface[];
  eventColor?: any;
  parentType?: TaskType;
  duration?: number;
  phaseName?: string;
  percentDone?: number;
  timeZone?: string;
  isSplitted?: boolean;
}

export enum GanttFilterResourceStoreActionEnum {
  COLLAPSE = 'collapse',
  EXPAND = 'expand',
}

export interface GanttEventTooltipFeatureInterface {
  hoverDelay: number;
  hideDelay: number;
  template: (data: EventTooltipDataInterface) => string;
}

export interface IGanttDependencyItem {
  id: string | number;
  sequenceNumber: number;
  jobId: number;
}

export interface EventRendererDataInterface {
  eventRecord: GanttScenarioItem;
  renderData: any;
}

export class Drag extends DragHelper {
  static get defaultConfig() {
    return {
      mode: 'translateXY',
      callOnFunctions: true,
      autoSizeClonedTarget: false,
      unifiedProxy: true,
      removeProxyAfterDrop: false,
      cloneTarget: true,
      dropTargetSelector: '.b-timeline-subgrid',
      targetSelector: '.b-grid-row:not(.b-group-row)',
    };
  }

  override createProxy(grabbedElement): HTMLElement {
    const { context, schedule, grid }: any = this,
      draggedAppointment = grid.getRecordFromElement(grabbedElement),
      proxy: HTMLDivElement = document.createElement('div');
    proxy.style.cssText = '';

    const totalDurationMS: number =
      Number(draggedAppointment['originalData']?.totalDuration ?? draggedAppointment['originalData']?.duration) *
      60 *
      60 *
      1000;
    const durationInPixels = schedule.timeAxisViewModel.getDistanceForDuration(totalDurationMS);

    proxy.style.width = `${durationInPixels}px`;
    proxy.style.height = `${schedule.rowHeight - 2 * schedule.resourceMargin}px`;
    proxy.style.marginTop = '40px';

    proxy.classList.add('b-sch-event-wrap', 'b-sch-style-border', 'b-unassigned-class', 'b-sch-horizontal');
    proxy.innerHTML = draggedAppointment?.name;
    context.task = draggedAppointment?.data;

    return proxy;
  }
}

export class GridWidget {
  constructor(config: Partial<ContainerItemConfig>, gridTitleTranslationSettings?: IGridTitleTranslationSettings) {
    return GridWidget.initialConfig(config, gridTitleTranslationSettings);
  }

  static initialConfig(
    config: Partial<ContainerItemConfig>,
    gridTitleTranslationSettings?: IGridTitleTranslationSettings,
  ): Partial<ContainerItemConfig> {
    const defaultGridColumnConfig: Partial<GridColumnConfig> = {
      flex: 1,
      filterable: {
        filterField: {
          placeholder: gridTitleTranslationSettings?.searchTitle,
        },
      },
    };

    return {
      selectionMode: {
        cell: false,
      },
      // @ts-ignore
      type: 'grid',
      minHeight: '50%',
      appendTo: 'main',
      collapsible: true,
      features: {
        filterBar: {
          compactMode: false,
        },
        search: true,
        stripe: true,
        sort: 'name',
        cellEdit: false,
        cellMenu: false,
        headerMenu: false,
        rowCopyPaste: false,
      },
      columns: [
        {
          ...defaultGridColumnConfig,
          field: 'name',
          text: gridTitleTranslationSettings?.nameTitle,
          sortable(lhs, rhs) {
            return lhs.name.localeCompare(rhs.name);
          },
        },
        {
          ...defaultGridColumnConfig,
          field: 'productName',
          text: gridTitleTranslationSettings?.detailTitle,
          sortable(lhs, rhs) {
            return lhs.productName.localeCompare(rhs.productName);
          },
        },
        {
          text: gridTitleTranslationSettings?.durationTitle,
          flex: 1,
          align: 'center',
          field: 'fullDuration',
          renderer: ({ record }) => `${record.totalDuration} h`,
          filterable: false,
        },
      ],
      rowHeight: 35,
      disableGridRowModelWarning: true,
      ...config,
    };
  }
}

export class SubtaskTab extends EditorTab {
  private static translate: TranslateService;
  private grid: Grid;

  constructor(private helperService: HelperService, translate: TranslateService) {
    SubtaskTab.translate = translate;
    super();
  }

  static get defaultConfig() {
    return {
      weight: 200,
      title: SubtaskTab.translate.instant('eventEditor.subtaskTab.title'),
      cls: 'b-tab-subtasks',
      autoUpdateRecord: false,
      layoutStyle: {
        flexFlow: 'column nowrap',
      },
      disabled: false,
      items: {
        subEvents: {
          type: 'grid',
          name: 'subEvents',
          flex: '1 1 auto',
          width: '100%',
          store: {
            sorters: [{ field: 'startDate', ascending: true }],
            modelClass: EventModel,
          },
          columns: [
            {
              field: 'name',
              text: SubtaskTab.translate.instant('general.dataTable.header.name'),
              flex: 1,
              editor: {
                disabled: true,
              },
            },
            {
              field: 'startDate',
              text: SubtaskTab.translate.instant('general.dataTable.header.startDate'),
              flex: 1,
              type: 'date',
              format: mysqlTimestampFormat,
              editor: {
                type: 'datetimefield',
                timeField: {
                  stepTriggers: false,
                },
                dateField: {
                  stepTriggers: false,
                },
              },
            },
            {
              field: 'endDate',
              text: SubtaskTab.translate.instant('general.dataTable.header.endDate'),
              flex: 1,
              type: 'date',
              format: mysqlTimestampFormat,
              editor: {
                type: 'datetimefield',
                timeField: {
                  stepTriggers: false,
                },
                dateField: {
                  stepTriggers: false,
                },
              },
            },
          ],
        },
        toolbar: {
          type: 'toolbar',
          flex: '0 0 auto',
          cls: 'b-compact-bbar',
          namedItems: {
            remove: {
              name: 'removeButton',
              type: 'button',
              cls: 'b-remove-button b-red',
              icon: 'b-icon b-icon-trash',
              onClick: 'up.onDeleteClick',
              disabled: false,
            },
          },
          items: {
            remove: true,
          },
        },
      },
    };
  }

  override construct() {
    super.construct(...arguments);

    this.grid = this.widgetMap['subEvents'] as Grid;
    (this.grid.store as Store).on({
      change: 'onStoreChange',
      thisObj: this,
    });
  }

  onStoreChange({ action, records }) {
    const { record } = this;

    if (action === 'remove') {
      record.removeChild(records);
    }
  }

  onDeleteClick() {
    const selectedRecord: Model = this.grid.selectedRecord;
    const selectedEvent: GanttScenarioItem = selectedRecord['originalData'];

    if (
      selectedEvent?.parentId &&
      selectedEvent.type === TaskType.unplannedWorkOrder &&
      selectedEvent?.parentType === TaskType.unplannedWorkOrder
    ) {
      this.helperService.showToastMessage(
        false,
        null,
        SubtaskTab.translate.instant('scheduler.childWorkOrderCannotBeDeleted.message'),
      );

      return;
    }

    this.grid.features.cellEdit.cancelEditing(true);
    selectedRecord && (this.grid.store as Store).remove(selectedRecord);
  }

  //@ts-ignore
  set record(record: any): any {
    super.record = record;

    if (record) {
      this['_tab'].disabled = !!record.parentId;

      if (this.items[1]['_items'].idMap['b-button-1']) {
        this.items[1]['_items'].idMap['b-button-1'].disabled = record.type === TaskType.downTimeActivity;
      }

      _.get(this.grid, 'store').loadData(record.children || []);
    } else {
      this.grid.features.cellEdit.finishEditing();
    }
  }

  override get record() {
    return super.record;
  }
}

export interface IEventColoring {
  color: string;
  cls?: string;
}

export interface IGantEventProduct {
  id: number;
  productId: string;
  productFamilyId?: number;
  customer?: CustomerInterface;
}
